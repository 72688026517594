import * as React from "react";
import Icons from ".";
const KeyIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_286_425"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <rect
        x={2}
        y={2}
        width={20}
        height={20}
        fill={props.color || Icons.IconColors.idle}
      />
    </mask>
    <g mask="url(#mask0_286_425)">
      <path
        d="M7.83398 13.6667C7.37565 13.6667 6.98329 13.5035 6.6569 13.1771C6.33051 12.8507 6.16732 12.4583 6.16732 12C6.16732 11.5417 6.33051 11.1493 6.6569 10.8229C6.98329 10.4965 7.37565 10.3333 7.83398 10.3333C8.29232 10.3333 8.68468 10.4965 9.01107 10.8229C9.33746 11.1493 9.50065 11.5417 9.50065 12C9.50065 12.4583 9.33746 12.8507 9.01107 13.1771C8.68468 13.5035 8.29232 13.6667 7.83398 13.6667ZM7.83398 17C6.4451 17 5.26454 16.5139 4.29232 15.5417C3.3201 14.5694 2.83398 13.3889 2.83398 12C2.83398 10.6111 3.3201 9.43056 4.29232 8.45833C5.26454 7.48611 6.4451 7 7.83398 7C8.76454 7 9.60829 7.22917 10.3652 7.6875C11.1222 8.14583 11.7229 8.75 12.1673 9.5H19.5007L22.0007 12L18.2507 15.75L16.584 14.5L14.9173 15.75L13.1465 14.5H12.1673C11.7229 15.25 11.1222 15.8542 10.3652 16.3125C9.60829 16.7708 8.76454 17 7.83398 17ZM7.83398 15.3333C8.61176 15.3333 9.29579 15.0972 9.88607 14.625C10.4763 14.1528 10.8687 13.5556 11.0632 12.8333H13.6673L14.8757 13.6875L16.584 12.4167L18.0632 13.5625L19.6257 12L18.7923 11.1667H11.0632C10.8687 10.4444 10.4763 9.84722 9.88607 9.375C9.29579 8.90278 8.61176 8.66667 7.83398 8.66667C6.91732 8.66667 6.1326 8.99306 5.47982 9.64583C4.82704 10.2986 4.50065 11.0833 4.50065 12C4.50065 12.9167 4.82704 13.7014 5.47982 14.3542C6.1326 15.0069 6.91732 15.3333 7.83398 15.3333Z"
        fill={props.color || Icons.IconColors.idle}
      />
    </g>
  </svg>
);
export default KeyIcon;
