import * as React from "react";
import Icons from ".";
const BackIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 20L4 12L12 4L13.1748 5.17483L7.17468 11.175H20V12.825H7.17468L13.1748 18.8252L12 20Z" fill={props.color || Icons.IconColors.idle}/>
    </svg>
);
export default BackIcon;
