import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UIProvider } from './contexts/UICtx';
import { AuthProvider } from './contexts/AuthCtx';
import { BrowserRouter } from "react-router-dom";

import "./styles/index.scss";
import Axios from './contexts/AxiosCtx';
import { SkProvider } from './contexts/SkCtx';
import { DatastoreProvider } from './contexts/DatastoreCtx';
import PlatformRouter from './routes/generic';
import { isChrome } from './env';

let token = null;

if(isChrome()) {
  window.chrome.runtime.sendMessage(
    {
      action: "getCookie"
    },
    (response) => {
      token = response.value || null;
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <HelmetProvider>
          <CookiesProvider>
            <PlatformRouter>
              <Axios chromeToken={token}>
                <DatastoreProvider>
                  <SkProvider>
                    <UIProvider>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </UIProvider>
                  </SkProvider>
                </DatastoreProvider>
              </Axios>
            </PlatformRouter>
          </CookiesProvider>
        </HelmetProvider>
      );

      reportWebVitals();
    }
  );
}
else
{
  const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <HelmetProvider>
          <CookiesProvider>
            <PlatformRouter>
              <Axios chromeToken={null}>
                <DatastoreProvider>
                  <SkProvider>
                    <UIProvider>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </UIProvider>
                  </SkProvider>
                </DatastoreProvider>
              </Axios>
            </PlatformRouter>
          </CookiesProvider>
        </HelmetProvider>
      );

      reportWebVitals();
}