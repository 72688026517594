import { useEffect, useState } from "react";
import { useSk } from "../contexts/SkCtx";
import { getAppPlatform, platformList } from "../env";

export function LaunchPage() {

    const skCtx = useSk();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(window.location.href);
    }, []);

    useEffect(() => {
        if(url) {
            let main_url = new URL(url);
            let u = decodeURIComponent(main_url.pathname.replace("/", ""));
            if(getAppPlatform() === platformList.chrome && u === 'index.html') {
                return;
            }
            skCtx.find(u);
        }
    }, [url]);

    useEffect(() => {
        if(skCtx.finder_suggestions.list.length) {
            let item = skCtx.finder_suggestions.list[0];
            skCtx.launchAction(item, 0);
        }
    }, [skCtx.finder_suggestions]);

    return (
        <></>
    );
}