import { useEffect, useState } from "react";
import ShortsLauncherContainer from "../components/Launcher";
import { isChrome } from "../env";
import { useSk } from "../contexts/SkCtx";
import { useAuth } from "../contexts/AuthCtx";
import { ButtonText } from "../components/Buttons";

function Logo() {
    return (
        <div className="logoContainer">
            <img className="logo" src="/shortkey_logo.svg" alt="shortkey-logo" />
        </div>
    );
}

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <div className="login-form">
                <input type="email" placeholder="Email" className="input-field" />
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="input-field"
                />
                <div className="options">
                    <a href="#">Forget password?</a>
                    <button
                        className="show-password"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? "Hide password" : "Show password"}
                    </button>
                </div>
                <div className="login-button">
                    <ButtonText style={{ width: "100%" }}>Login</ButtonText>
                </div>
            </div>
            <div className="signup">
                <p>Don’t have an account? <a href="#">Sign up</a></p>
            </div>
        </>
    );
};

function LoginPage() {

    useEffect(() => {
        if (isChrome()) {
        }
    }, []);

    const authCtx = useAuth();
    const skCtx = useSk();

    return (
        <>
            <LoginForm />
        </>
    );
}

export default LoginPage;