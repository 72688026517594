import SearchIcon from "./SearchIcon";
import ControlIcon from "./ControlIcon";
import MenuIcon from "./MenuIcon";
import PencilIcon from "./PencilIcon";
import CloseIcon from "./CloseIcon";
import BackIcon from "./BackIcon";
import DragIcon from "./DragIcon";
import AddIcon from "./AddIcon";
import DeleteIcon from "./DeleteIcon";
import DeleteFilledIcon from "./DeleteFilled";
import BookmarkIcon from "./BookmarkIcon";
import SettingIcon from "./SettingIcon";
import MoreHorizIcon from "./MoreHorizIcon";
import LabelIcon from "./LabelIcon";
import ArrowUpIcon from "./ArrowUpIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import EnterIcon from "./EnterIcon";
import KeyIcon from "./KeyIcon";
import BookmarkFilledIcon from "./BookmarkFilledIcon";
import OpenExternalIcon from "./OpenExternalIcon";
import AngleDownIcon from "./AngleDown";
import AngleUpIcon from "./AngleUp";
import CheckIcon from "./CheckIcon";
import UserIcon from "./UserIcon";
import AppIcon from "./AppIcon";

const IconColors = {
    active: "#542E60",
    idle: "#616161",
    light: "#E1E1E1",
    warning: "rgb(255, 119, 119)",
    white: "#F5F5F5"
}

export default {
    AppIcon,
    SearchIcon,
    ControlIcon,
    MenuIcon,
    PencilIcon,
    CloseIcon,
    BackIcon,
    DragIcon,
    AddIcon,
    DeleteIcon,
    BookmarkIcon,
    SettingIcon,
    MoreHorizIcon,
    LabelIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    EnterIcon,
    KeyIcon,
    BookmarkFilledIcon,
    OpenExternalIcon,
    DeleteFilledIcon,
    AngleDownIcon,
    AngleUpIcon,
    CheckIcon,
    UserIcon,

    IconColors
}