
import { useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { getBearerTokenFormat } from "../api";
import axiosInstance from "../api/helper";
import { CookiesArray, CookiesList } from "../constants/cookies";
import { isChrome } from "../env";

const Axios = ({ children, chromeToken }) => {
  const [cookies] = useCookies(CookiesArray);
  const [task_done, setTaskDone] = useState(false);

  // useMemo(() => {

  //   let access_token = undefined;

  //   if(isChrome() && window.chrome.runtime) {
  //     access_token = 0;
  //     window.chrome.runtime.sendMessage(
  //       {
  //         action: "getCookie"
  //       },
  //       (response) => {

  //         if (response.success) {

  //           axiosInstance.interceptors.request.use(
  //             (config) => {
  //               const token = access_token;
  //               if (token) {
  //                 config.headers["Authorization"] = getBearerTokenFormat(response.value);
  //               }
  //               return config;
  //             },
  //             (error) => {
  //               return Promise.reject(error);
  //             }
  //           );

  //         } else {
  //           console.log("Error:", response.message);
  //         }

  //         setTaskDone(true);
  //       }
  //     );
  //   }
  //   else
  //   {
  //     access_token = cookies[CookiesList.AccessToken];
  //     axiosInstance.interceptors.request.use(
  //       (config) => {
  //         const token = access_token;
  //         if (token) {
  //           config.headers["Authorization"] = getBearerTokenFormat(token);
  //         }
  //         return config;
  //       },
  //       (error) => {
  //         return Promise.reject(error);
  //       }
  //     );
  //     setTaskDone(true);
  //   }

  // }, []);

  // return (task_done ? children : <></>);

  useMemo(() => {

    let access_token = null;
    access_token = isChrome() ? chromeToken : cookies[CookiesList.AccessToken];
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = access_token;
        if (token) {
          config.headers["Authorization"] = getBearerTokenFormat(token);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

  }, []);

  return children

};

export default Axios;
