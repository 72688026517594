import * as React from "react";
import Icons from ".";
const CloseIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 13.4267L5.72228 19.7044C5.52527 19.9015 5.28748 20 5.00892 20C4.73037 20 4.49259 19.9015 4.29555 19.7044C4.09852 19.5074 4 19.2696 4 18.9911C4 18.7125 4.09852 18.4747 4.29555 18.2777L10.5733 12L4.29555 5.72228C4.09852 5.52527 4 5.28748 4 5.00892C4 4.73037 4.09852 4.49259 4.29555 4.29555C4.49259 4.09852 4.73037 4 5.00892 4C5.28748 4 5.52527 4.09852 5.72228 4.29555L12 10.5733L18.2777 4.29555C18.4747 4.09852 18.7125 4 18.9911 4C19.2696 4 19.5074 4.09852 19.7044 4.29555C19.9015 4.49259 20 4.73037 20 5.00892C20 5.28748 19.9015 5.52527 19.7044 5.72228L13.4267 12L19.7044 18.2777C19.9015 18.4747 20 18.7125 20 18.9911C20 19.2696 19.9015 19.5074 19.7044 19.7044C19.5074 19.9015 19.2696 20 18.9911 20C18.7125 20 18.4747 19.9015 18.2777 19.7044L12 13.4267Z"
            fill={props.color || Icons.IconColors.idle}
        />
    </svg>
);
export default CloseIcon;
