import { useRef, useState } from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { getAppPlatform, platformList } from "../env";

export default function PlatformRouter({ children }) {

    const p = useRef(getAppPlatform());

    return (
        <>
            {p.current === platformList['chrome'] ?
                (<MemoryRouter>
                    {children}
                </MemoryRouter>)
                : (
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                )}
        </>
    );
}