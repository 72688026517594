import * as React from "react";
import Icons from ".";
const MoreHorizIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_267_645"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_267_645)">
            <path
                d="M6 14C5.45 14 4.97933 13.804 4.588 13.412C4.196 13.0207 4 12.55 4 12C4 11.45 4.196 10.979 4.588 10.587C4.97933 10.1957 5.45 10 6 10C6.55 10 7.02067 10.1957 7.412 10.587C7.804 10.979 8 11.45 8 12C8 12.55 7.804 13.0207 7.412 13.412C7.02067 13.804 6.55 14 6 14ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM18 14C17.45 14 16.979 13.804 16.587 13.412C16.1957 13.0207 16 12.55 16 12C16 11.45 16.1957 10.979 16.587 10.587C16.979 10.1957 17.45 10 18 10C18.55 10 19.021 10.1957 19.413 10.587C19.8043 10.979 20 11.45 20 12C20 12.55 19.8043 13.0207 19.413 13.412C19.021 13.804 18.55 14 18 14Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default MoreHorizIcon;
