import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { Button, ButtonIcon, ButtonText } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";
import Input from "../Input";
import { useSk } from "../../contexts/SkCtx";
import { TextArea } from "../TextArea";


export default function ExtensionSetupDialog({data, setData, updateIdRef}) {
  const authCtx = useAuth();
  const uiCtx = useUI();
  const skCtx = useSk();

  const onCloseClick = (e) => {
    uiCtx.setDialog(false);
  };

  const handleSetting = (setting_key) => (e) => {
    authCtx.updateSetting({
      ...skCtx.setting,
      [setting_key]: e.target.checked,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("is_setup", "true");
    }, 100);
  }, []);


  return (
    <>
      <>
        <div className="spacer mb-12"></div>
        <div className="dialog_col titlebar common-shorts-w"></div>
        <div className="spacer h-12"></div>
        <div className="dialog_col input-container">
          <div className="inner-col">
            <CheckboxControl
                  onChange={handleSetting("new_tab")}
                  label={"Shortkey as new tab"}
                  jsText={false}
                  desc={`Open Shortkey everytime you open a newtab.`}
                  disabled={false}
                  isChecked={skCtx.setting["new_tab"]}
                  centerLabel={true}
                />
          </div>
        </div>
        <div className="spacer h-30"></div>
        <div className="dialog_col input-container">
          <div className="inner-col">
            <CheckboxControl
                  onChange={handleSetting("auto_focus")}
                  label={"Active input field"}
                  jsText={false}
                  desc={`Write on new tabs directly in the shortkey instead of browser input field.`}
                  disabled={false}
                  isChecked={skCtx.setting["auto_focus"]}
                  centerLabel={true}
                />
          </div>
        </div>
        <div className="spacer h-30"></div>
        <div className="footer-buttons">
          <ButtonText onClick={onCloseClick}>Done</ButtonText>
        </div>
        <div className="spacer h-30"></div>
      </>
    </>
  );
}
