import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAuth } from "../contexts/AuthCtx";
import AuthService from "../api/auth";

const AuthPage = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const authCtx = useAuth();
    
    useEffect(() => {
        console.log("TXXXk :: ", token)
        if(token) {
            let c = token.split("|");
            AuthService.loginLoginSign({
                authcode: c[0],
                connectedCode: c[1] || null
            }).then((r) => {
                authCtx.handleSetAuthCookie(r.data.token);
            })
            // authCtx.handleSetAuthCookie(token);
        }
    }, [token]);
    
    return (
        <>
        </>
    );
}

export default AuthPage;