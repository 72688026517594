import * as React from "react";
import Icons from ".";
const ArrowUpIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_269_647"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect
                x={24}
                y={24}
                width={24}
                height={24}
                transform="rotate(-180 24 24)"
                fill={props.color || Icons.IconColors.idle}
            />
        </mask>
        <g mask="url(#mask0_269_647)">
            <path
                d="M12 6L18 12L16.6 13.4L13 9.8V19H11V9.8L7.4 13.4L6 12L12 6Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default ArrowUpIcon;
