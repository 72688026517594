import { forwardRef, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const PopupMenu = forwardRef(({
    position,
    visible,
    style,
    onClose,
    children,
    haveBackdrop
}, ref) => {

    const [visibility, setVisibility] = useState(visible || false);
    const popupRef = useRef(null);

    useOnClickOutside(popupRef, (e) => {
        e.stopPropagation();
        e.preventDefault();
        setVisibility(false);
        onClose();
    }, () => {}, visibility);

    useEffect(() => {
        setVisibility(visible);
    }, [visible]);

    return (
        <>
        {!haveBackdrop && visibility && <div className="popupbackdrop-window"></div>}
            {visibility && (
                <div
                    ref={popupRef}
                    className="popupMenu"
                    style={{
                        ...style,
                        ...position
                    }}>
                        <div
                            className="innerPopup">
                                {children}
                        </div>
                </div>
            )}
        </>
    );

});

export function PopupButton({
    icon,
    text,
    textClassName,
    extraIcon,
    ...props
}) {
    return (
        <button
            {...props} className={["button", "popupButton", props.className].join(" ")}>
                {icon && <span className="icon">
                    {icon}
                </span>}
                {text && <span className={textClassName}>
                    {text}
                </span>}
                {extraIcon && <span className="icon">
                    {extraIcon}
                </span>}
        </button>
    );
}

export default PopupMenu;