import { useAuth } from "./contexts/AuthCtx";
import LoadingPage from "./pages/loading";
import { Router } from "./routes/Router";

function App() {
  const authCtx = useAuth();
  return <>{authCtx.initialized ? <Router /> : <LoadingPage />}</>;
}

export default App;
