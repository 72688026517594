import * as React from "react";
import Icons from ".";
const ArrowDownIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_269_650"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_269_650)">
            <path
                d="M12 18L6 12L7.4 10.6L11 14.2V5H13V14.2L16.6 10.6L18 12L12 18Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default ArrowDownIcon;
