import * as React from "react";
import Icons from ".";
const UserIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_476_244"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={20}
            height={20}
        >
            <rect width={20} height={20} fill="#616161" />
        </mask>
        <g mask="url(#mask0_476_244)">
            <path
                d="M9.9987 9.9987C9.08203 9.9987 8.29731 9.67231 7.64453 9.01953C6.99175 8.36675 6.66536 7.58203 6.66536 6.66536C6.66536 5.7487 6.99175 4.96398 7.64453 4.3112C8.29731 3.65842 9.08203 3.33203 9.9987 3.33203C10.9154 3.33203 11.7001 3.65842 12.3529 4.3112C13.0056 4.96398 13.332 5.7487 13.332 6.66536C13.332 7.58203 13.0056 8.36675 12.3529 9.01953C11.7001 9.67231 10.9154 9.9987 9.9987 9.9987ZM3.33203 16.6654V14.332C3.33203 13.8598 3.45356 13.4258 3.69661 13.0299C3.93967 12.6341 4.26259 12.332 4.66536 12.1237C5.52648 11.6931 6.40148 11.3702 7.29036 11.1549C8.17925 10.9397 9.08203 10.832 9.9987 10.832C10.9154 10.832 11.8181 10.9397 12.707 11.1549C13.5959 11.3702 14.4709 11.6931 15.332 12.1237C15.7348 12.332 16.0577 12.6341 16.3008 13.0299C16.5438 13.4258 16.6654 13.8598 16.6654 14.332V16.6654H3.33203Z"
                fill="#616161"
            />
        </g>
    </svg>
);
export default UserIcon;
