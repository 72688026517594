import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthCtx";
import { useSk } from "../../contexts/SkCtx";
import { DialogTabList, useUI } from "../../contexts/UICtx";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { ButtonIcon } from "../Buttons";
import Icons from "../Icons";
import InitService from "../../api/init";
import PencilIcon from "../Icons/PencilIcon";
import { isChrome } from "../../env";
import AuthService from "../../api/auth";

function Logo() {
  return (
    <div className="logoContainer">
      <img className="logo" src="/shortkey_logo.svg" alt="shortkey-logo" />
    </div>
  );
}

function InitialShortkeyInput({ showAIAnswer, onMultilineAcitve }) {
  const finderRef = useRef(null);

  const skCtx = useSk();
  const uiCtx = useUI();
  const authCtx = useAuth();

  const [initialHeight, setInitialHeight] = useState(-1);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);

  useEffect(() => {
    if (finderRef.current) {
      finderRef.current.value = "";
      skCtx.find("");
      if (!skCtx.isMobile()) {
        // finderRef.current.focus();
      }
      setInitialHeight(finderRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyUp = (e) => {
    // let v = (e.target.innerText || "").trim();

    // if (v.length > 0) {
    //   if (v.includes("\n")) {
    //     onMultilineAcitve(true);
    //   } else {
    //     onMultilineAcitve(false);
    //   }
    // } else {
    //   onMultilineAcitve(false);
    // }

    if (!initialHeight) {
      return;
    }

    let v = (e.target.innerText || "").trim();
    let currentHeight = e.target.clientHeight;
    console.log("CH : ", currentHeight, initialHeight);
    if (v.length > 0) {
      if (currentHeight > initialHeight) {
        onMultilineAcitve(true);
      } else {
        onMultilineAcitve(false);
      }
    } else {
      onMultilineAcitve(false);
    }

  };
  const onKeyDown = (e) => {
    const {
      key,
      target: { textContent },
    } = e;
    if (!textContent) return;

    let ai_mode = 'none';

    if (key === '?') {
      // if (e.target.textContent.trim().length > 0) {
      //   let q = e.target.textContent.trim();
      //   showAIAnswer(q, false, true);
      // }
      // return;
      ai_mode = 'ask';
    }
    else if (key === '!') {
      // if (e.target.textContent.trim().length > 0) {
      //   let q = e.target.textContent.trim();
      //   showAIAnswer(q, true, true);
      // }
      // return;
      ai_mode = 'fix';
    }
    else {
      showAIAnswer('', false, false);
    }

    if (key === "ArrowUp" || key === "ArrowDown") {
      if (skCtx.categories && skCtx.categories.parsed && skCtx.categories.current_cat1 && skCtx.categories.current_cat2) {

      } else {
        e.preventDefault(); // prevent page scroll
        let newIndex = null;
        if (key === "ArrowUp") {
          if (skCtx.finder_navigation.active_index > 0) {
            newIndex = skCtx.finder_navigation.active_index - 1;
          } else {
            newIndex = skCtx.finder_suggestions.list.length - 1;
          }
        } else {
          if (
            skCtx.finder_navigation.active_index <
            skCtx.finder_suggestions.list.length - 1
          ) {
            newIndex = skCtx.finder_navigation.active_index + 1;
          } else {
            newIndex = 0;
          }
        }
        skCtx.setFinderNavigation({
          active_index: newIndex,
        });
      }
    } else if (key === "Enter") {
      // console.log("K :: ", key, e)

      if (e.shiftKey) {
        return;
      }

      let idx = skCtx.finder_navigation.active_index;
      console.log("🚀 ~ onKeyDown ~ idx:", idx);
      e.preventDefault(); // prevent page scroll

      /** launch the active */
      if (idx > -1 && idx < skCtx.finder_suggestions.list.length) {
        let item = skCtx.finder_suggestions.list[idx];
        console.log("🚀 ~ onKeyDown ~ item:", item);
        if (item) {
          if (item.type === "add") {
            if (window.innerWidth > 500) {
              skCtx.setCurrentShortkey({
                // shortkeys: [skCtx.finder.user_query],
                shortkeys: [],
                title: skCtx.finder.user_query.replace("+", ""),
              });
              uiCtx.setDialog(true, DialogTabList.AddShort);
            }
            else {
              skCtx.setCurrentShortkey({
                // shortkeys: [skCtx.finder.user_query],
                shortkeys: [],
                title: skCtx.finder.user_query.replace("+", ""),
              });
              uiCtx.setDialog(true, DialogTabList.AddShort);
            }
          }
          else if (item.type === "collection") {
            authCtx.fetchAndAddCollection(item);
          }
          else {
            skCtx.launchAction(item, skCtx.finder_navigation.active_index);
          }
        }
      } else {
        skCtx.launchDefaultSearch(textContent);
      }

      /** check if the text is a url */
      // if (isValidUrl(textContent)) {
      //   const href =
      //     textContent.startsWith("http://") ||
      //     textContent.startsWith("https://")
      //       ? textContent
      //       : "http://" + textContent;
      //   window.location.href = href;
      //   return;
      // }
      // /** launch the active */
      // if (idx > -1 && idx < skCtx.finder_suggestions.list.length) {
      //   let item = skCtx.finder_suggestions.list[idx];
      //   console.log("🚀 ~ onKeyDown ~ item:", item);
      //   if (item && item.type === "add") {
      //     skCtx.setCurrentShortkey({
      //       shortkeys: [skCtx.finder.user_query],
      //       title: skCtx.finder.user_query,
      //     });
      //     uiCtx.setDialog(true, DialogTabList.AddShort);
      //   } else {
      //     skCtx.launchAction(item, skCtx.finder_navigation.active_index);
      //   }
      // } else {
      //   skCtx.launchDefaultSearch(textContent);
      // }
    }
  };

  const isValidUrl = (url) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?" + // port
      "(\\/[-a-z\\d%_.~+]*)*" + // path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  const onChange = (e) => {
    if ((e.target.innerText || '').length > 0) {
      setPlaceholderVisible(false);
    }
    else {
      setPlaceholderVisible(true);
    }

    let return_data = skCtx.find(e.target.innerText);

    onKeyUp(e);
  };

  const onClearText = (e) => {
    if (finderRef.current) {
      finderRef.current.innerText = "";
      if (!(skCtx.isMobile())) {
        // finderRef.current.focus();
      }
      onChange({ target: finderRef.current });
      if (!(skCtx.isMobile())) {
        // finderRef.current.focus();
      }
    }
    // if (skCtx.isMobile() && skCtx.mobile_view) {
    //   skCtx.setMobileView(false);
    // }
    if (skCtx.isMobile() && skCtx.activate_mobile_view) {
      skCtx.setActivateMobileView(false);
    }
    skCtx.setAIConversations([]);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = (e.clipboardData || window.clipboardData).getData('text');
    document.execCommand('insertText', false, text);
  }

  useEffect(() => {
    if (skCtx.isMobile() && !skCtx.activate_mobile_view) {
      finderRef.current.blur();
    }
    if (skCtx.isMobile() && skCtx.activate_mobile_view) {
      finderRef.current.focus();
    }
  }, []);

  const handleOnFocus = (e) => {
    // if (skCtx.isMobile() && !skCtx.mobile_view) {
    //   // skCtx.setMobileView(true);
    // }

    // if (skCtx.isMobile() && !skCtx.activate_mobile_view) {
    //   skCtx.setActivateMobileView(true);
    // }
  }

  const handleOnClick = (e) => {
    if (skCtx.isMobile() && !skCtx.activate_mobile_view) {
      skCtx.setActivateMobileView(true);
    }
  }

  function moveCursorToEnd(contentEditableElement) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentEditableElement);
    range.collapse(false); // Move the cursor to the end
    selection.removeAllRanges();
    selection.addRange(range);
  }

  useEffect(() => {
    console.log("incre :: ", skCtx.active_widget)
    if (skCtx.active_widget && skCtx.active_widget.is_live) {
      if ((skCtx.finder.parsed_data.data || '').trim().length < 1) {
        finderRef.current.innerHTML = finderRef.current.innerHTML + "&nbsp;";
        moveCursorToEnd(finderRef.current);
      }
    }
  }, [skCtx.active_widget]);

  const handleOnBlur = (e) => {
    // if(skCtx.isMobile() && skCtx.mobile_view) {
    //   skCtx.setMobileView(false);
    // }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {

      console.log("EKEY :: ", event.key, event)

      if (event.key.length > 1) {
        return;
      }

      if (event.ctrlKey || event.metaKey || event.altKey) {
        return;
      }

      const activeElement = document.activeElement;
      const isEditableElementFocused =
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.contentEditable === 'true';

      // Only focus the input if no other input, textarea, or contentEditable element is focused
      if (!isEditableElementFocused && finderRef.current) {
        finderRef.current.focus();
      }
    };

    // Add keydown event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOpenSidebar = (e) => {
    window.location.hash = "shorts";
  }

  return (
    <div className={("innerShortsLauncherInputContainer initialInputContainer") + (skCtx.mobile_view ? " -mobile-view___IGNORED" : "")}>
      <span className="innerInputContainer">
        <span className="input-control">
          <span
            className="transparent"
            onKeyDown={onKeyDown}
            // onCh={onKeyUp}
            onInput={onChange}
            ref={finderRef}
            autoFocus={window.innerWidth > 450 ? true : false}
            spellCheck={false}
            autoCorrect={"off"}
            autoCapitalize={"off"}
            onFocus={handleOnFocus}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            id="shortkey-launcher-input"
            contentEditable={true}
            onPaste={handlePaste}
          ></span>
          {placeholderVisible && skCtx.default_search && <span className="placeholder">{skCtx.default_search.title}</span>}
        </span>
        {skCtx.finder && (skCtx.finder["is_active"] || skCtx.activate_mobile_view) ? (
          <span className="active-search-close-icon-container">
            <ButtonIcon onClick={onClearText}>
              <Icons.CloseIcon color={Icons.IconColors.active} />
            </ButtonIcon>
          </span>
        ) : (
          skCtx.setting['minimalistic'] ? <span className="active-search-close-icon-container">
            <ButtonIcon onClick={handleOpenSidebar}>
              <Icons.MenuIcon color={Icons.IconColors.active} />
            </ButtonIcon>
          </span> : <></>
        )}
      </span>
    </div>
  );
}

function CategoriesList({ }) {

  const skCtx = useSk();
  const catRef = useRef(null);
  const currentIdx = useRef(null);

  const handleCurrentCat = (k) => (e) => {
    skCtx.selectCategoryKey(k, null);
  }

  const handleCurrentSubCat = (k) => (e) => {
    skCtx.selectCategoryKey(skCtx.categories.current_cat1, k);
  }

  const handleSugg = (sugg, i) => (e) => {
    // let search_bar = document.querySelector("#shortkey-launcher-input");
    // search_bar.innerText = sugg.short;
    skCtx.selectCategoryKey(null, null, true);

    let search_bar = document.querySelector("#shortkey-launcher-input");

    // The text you want to "paste" or type into the contentEditable element
    let textToPaste = "!" + sugg.short;

    // Function to simulate typing
    function simulateTyping(element, text) {
      element.click();
      element.focus(); // Focus the contentEditable span

      text.split('').forEach((char, index) => {
        // Create keydown event
        let keydownEvent = new KeyboardEvent('keydown', {
          key: char,
          code: char.charCodeAt(0),
          charCode: char.charCodeAt(0),
          bubbles: true,
          cancelable: true
        });

        // Create keyup event
        let keyupEvent = new KeyboardEvent('keyup', {
          key: char,
          code: char.charCodeAt(0),
          charCode: char.charCodeAt(0),
          bubbles: true,
          cancelable: true
        });


        // Dispatch the keydown event
        element.dispatchEvent(keydownEvent);

        // Insert the character at the caret position
        document.execCommand('insertText', false, char);

        // Dispatch the keyup event
        element.dispatchEvent(keyupEvent);
      });


      // After typing the text, simulate the space key event
      let spaceKeydownEvent = new KeyboardEvent('keydown', {
        key: ' ',
        code: 'Space',
        charCode: 32,
        keyCode: 32,  // The key code for space
        bubbles: true,
        cancelable: true
      });

      let spaceKeyupEvent = new KeyboardEvent('keyup', {
        key: ' ',
        code: 'Space',
        charCode: 32,
        keyCode: 32,
        bubbles: true,
        cancelable: true
      });

      // Dispatch the space keydown event
      element.dispatchEvent(spaceKeydownEvent);

      // Insert a space character at the caret position
      document.execCommand('insertText', false, ' ');

      // Dispatch the space keyup event
      element.dispatchEvent(spaceKeyupEvent);

      skCtx.find(element.innerText, null, true);
    }

    // Call the function to simulate typing
    simulateTyping(search_bar, textToPaste);
  }

  const handleMouseOver = (i) => (e) => {
    skCtx.setFinderNavigation({
      active_index: i,
    });
  }

  const handleKeyDown = (sugg, i) => (e) => {

  }


  const hke = (e) => {
    const {
      key,
      target,
    } = e;
    if (catRef.current && catRef.current.parsed && catRef.current.current_cat1 && catRef.current.current_cat2 && currentIdx.current) {
      console.log("aaaaaaaaaaaa :: ", key, skCtx.finder_navigation.active_index, catRef);
      const list = catRef.current.search_result || catRef.current.parsed[catRef.current.current_cat1][catRef.current.current_cat2];
      if (list) {
        console.log("cuurent active idx :: ", currentIdx.current.active_index, list.length);

        // e.preventDefault(); // prevent page scroll
        let newIndex = null;
        if (key === "ArrowUp") {
          console.log("aaaaaaaaaa yyy", catRef.current, list)

          if (currentIdx.current.active_index > 0) {
            newIndex = currentIdx.current.active_index - 1;
          } else {
            newIndex = list.length - 1;
          }

          console.log("aaaaaaaaa :: ", newIndex, currentIdx.current.active_index)

        }
        else if (key === "ArrowDown") {
          if (
            currentIdx.current.active_index <
            list.length - 1
          ) {
            newIndex = currentIdx.current.active_index + 1;
          } else {
            newIndex = 0;
          }
        }

        console.log("NEW INDEX :: ", newIndex)

        if (newIndex !== null) {
          skCtx.setFinderNavigation({
            active_index: newIndex,
          });
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', hke);
    return () => {
      document.removeEventListener('keydown', hke);
    };
  }, []);

  useEffect(() => {

    if (skCtx.categories && skCtx.categories.parsed && skCtx.categories.current_cat2) {
      console.log("ctx :: ", skCtx.categories, skCtx.categories.parsed[skCtx.categories.current_cat1][skCtx.categories.current_cat2], skCtx.categories.current_cat1, skCtx.categories.current_cat2)
      // skCtx.setFinderNavigation({
      //   active_index: -1,
      // });
    }

    catRef.current = skCtx.categories;

  }, [skCtx.categories, skCtx.categories.current_cat1, skCtx.categories.current_cat2, skCtx.categories.search_result]);

  useEffect(() => {
    // skCtx.setFinderNavigation({
    //   active_index: 0,
    // });
    // let s = document.querySelector(".logo");
    // if(s) {
    //   s.click();
    // }
  }, [skCtx.categories.search_result]);

  useEffect(() => {
    currentIdx.current = skCtx.finder_navigation;
  }, [skCtx.finder_navigation]);

  const getData = () => {
    if(skCtx.categories.search_result && skCtx.categories.search_result.length > 0) {
      return skCtx.categories.search_result;
    }
    return skCtx.categories.parsed[skCtx.categories.current_cat1][skCtx.categories.current_cat2];
  }

  return (
    <>
      <div className="categoriesContainer">
        <div>
          {skCtx.categories.current_cat1 && <div className="categories-title">
            <div>
              <button onClick={(e) => {
                handleCurrentSubCat(null)(e);
                handleCurrentCat(null)(e);
              }}>Categories</button>
              <span style={{ transform: "rotate(90deg)" }}>
                <Icons.AngleUpIcon />
              </span>
              <button onClick={
                (e) => { handleCurrentSubCat(null)(e); }
              }>{skCtx.categories.current_cat1}</button>
              {skCtx.categories.current_cat2 && (
                <>
                  <span style={{ transform: "rotate(90deg)" }}>
                    <Icons.AngleUpIcon />
                  </span>
                  <span>{skCtx.categories.current_cat2}</span>
                </>
              )}
            </div>
          </div>}
          {skCtx.categories.current_cat1 && skCtx.categories.current_cat2 ? <></> : <div className="categories-list">
            {skCtx.categories.current_cat1 ? Object.keys(skCtx.categories.parsed[skCtx.categories.current_cat1]).map((k, i) => {
              return (
                <button onClick={handleCurrentSubCat(k)} key={`${i}`} className="cat-btn">{k}</button>
              );
            }) : Object.keys(skCtx.categories.parsed).map((k, i) => {
              return (
                <button onClick={handleCurrentCat(k)} key={`${i}`} className="cat-btn">{k}</button>
              );
            })}
          </div>}
          {skCtx.categories.current_cat1 && skCtx.categories.current_cat2 && <div className="link-list">
            {(getData()).map((sugg, i) => {
              return (
                <div
                  key={`${i}`}
                  className={
                    "shortsSuggestion" +
                    (skCtx.finder_navigation.active_index === i ? " active" : "")
                  }
                  onClick={handleSugg(sugg, i)}
                  tabIndex={"0"}
                  onMouseOver={handleMouseOver(i)}
                  onKeyDown={(e) => handleKeyDown(sugg, i)}
                >
                  <div className="inner">
                    <div>
                      <span>
                        {sugg.title}
                      </span>
                    </div>
                    <div className="auto">
                      <span>{'!' + sugg.short}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>}
        </div>
      </div>
    </>
  );
}

function SuggestionHints({ showAIAnswer }) {
  const skCtx = useSk();
  const uiCtx = useUI();
  const authCtx = useAuth();
  const widgetIframeRef = useRef(null);
  const quickNoteRef = useRef(null);
  const qnref = useRef(null);
  const placehodlerOfQuickNoteRef = useRef(null);
  const placeholderNeedsToShow = useRef(false);
  const [quicknotePlaceholderIsVisible, setQuickNotePlaceholderIsVisible] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const updateNoteRef = useRef(null);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleSugg = (sugg, index) => async (e) => {
    if (sugg.type === "add") {
      showAIAnswer('', false, false);
      if (window.innerWidth > 500) {
        skCtx.setCurrentShortkey({
          // shortkeys: [skCtx.finder.user_query],
          shortkeys: [],
          title: skCtx.finder.user_query.replace("+", ""),
        });
      }
      else {
        skCtx.setCurrentShortkey({
          // shortkeys: [skCtx.finder.user_query],
          shortkeys: [],
          title: skCtx.finder.user_query.replace("+", ""),
        });
      }
      uiCtx.setDialog(true, DialogTabList.AddShort);
      // }
      // else if(sugg.type === "ask") {
      //   showAIAnswer(skCtx.finder.user_query, false, true);
      // } else if(sugg.type === "fix_grammar") {
      //   showAIAnswer(skCtx.finder.user_query, true, true);
    }
    else if (sugg.type === "collection") {
      // setCollectionLoading(true);
      await authCtx.fetchAndAddCollection(sugg);
      // setCollectionLoading(false);
    }
    else {
      //   showAIAnswer('', false, false);
      skCtx.launchAction(sugg, skCtx.finder_navigation.active_index);
    }
  };
  const handleLoad = (e) => {
    if (skCtx.active_widget && skCtx.active_widget.is_live) {
      let d = JSON.stringify({
        key: skCtx.finder.parsed_data.key,
        arg: skCtx.finder.parsed_data.data
      });
      widgetIframeRef.current.contentWindow.postMessage(d, '*');
    }
  }
  const handleKeyDown = (sugg, index) => (e) => {
    console.log("key down :: ", sugg);
  };
  const handleMouseOver = (i) => (e) => {
    skCtx.setFinderNavigation({
      active_index: i,
    });
  };

  useEffect(() => {
    // console.log("AC :: ", skCtx.active_widget)
    if (skCtx.active_widget && skCtx.active_widget.is_live && widgetIframeRef.current) {
      // widgetIframeRef.current.contentWindow.postMessage(JSON.stringify({
      //   key: skCtx.finder.parsed_data.key,
      //   data: skCtx.finder.parsed_data.data
      // }), '*');
      // widgetIframeRef.current.addEventListener('load', (e) => {
      //   console.log("IFRAM ::" , e)
      // })
    }
  }, [skCtx.active_widget]);

  useEffect(() => {
    // if(quicknotePlaceholderIsVisible && s) {

    // }
    console.log("Quick note :: ", skCtx.quick_note)

    if (skCtx.quick_note) {
      if (quicknotePlaceholderIsVisible) {
        //   console.log("Placeholder")
        if (placehodlerOfQuickNoteRef.current) {
          if (!placeholderNeedsToShow.current) {
            placeholderNeedsToShow.current = true;
            placehodlerOfQuickNoteRef.current.click();
          }
        }
      }
    }
  }, [quicknotePlaceholderIsVisible, skCtx.quick_note]);

  useEffect(() => {
    // if(skCtx.quick_note && quickNoteRef.current) {
    //   quickNoteRef.current.innerHTML = skCtx.quick_note.val;
    // }
    if (skCtx.quick_note) {
      if ((skCtx.quick_note.val || "").trim().length > 0) {
        setQuickNotePlaceholderIsVisible(false);
      }
      else {
        setQuickNotePlaceholderIsVisible(true);
      }
      // quickNoteRef.current.innerHTML = skCtx.quick_note.val;
    }
  }, [skCtx.quick_note]);

  const handleEditQuickNote = (e) => {
    if (updateNoteRef.current) {
      clearTimeout(updateNoteRef.current);
    }
    updateNoteRef.current = setTimeout(() => {
      let v = '';
      if (placehodlerOfQuickNoteRef.current) {

      }
      else {
        v = e.target.innerHTML.trim() || "";
      }
      console.log("SAVING FROM :: ", e.target, ", val ::", v)
      localStorage.setItem("quicknote", v);
      if (authCtx.account && authCtx.account.signed) {
        AuthService.updateNote({
          global_note: v
        });
      }
      skCtx.launchQuickNote({});
    }, 1000);
  }

  const handleWidgetMessageListener = (event) => {
    if (event.origin !== window.location.origin) return; // Security check
    console.log('Received from iframe:', event.data);
  }

  const handleCopyEvalResult = (e) => {
    navigator.clipboard.writeText(`${skCtx.finder_suggestions.eval_result}`).then(() => {
      setCopied(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  useEffect(() => {
    window.addEventListener('message', handleWidgetMessageListener);
    return () => {
      window.removeEventListener('message', handleWidgetMessageListener);
    }
  }, []);

  useEffect(() => {
    if (skCtx.active_widget && skCtx.active_widget.is_live && widgetIframeRef.current) {
      widgetIframeRef.current.contentWindow.postMessage(skCtx.active_widget_message, '*');
    }
  }, [skCtx.active_widget_message]);

  const handleEditActiveNote = (e) => {
    if (updateNoteRef.current) {
      clearTimeout(updateNoteRef.current);
    }
    updateNoteRef.current = setTimeout(() => {
      let v = e.target.innerText.trim();
      authCtx.updateItem(
        skCtx.active_note._id,
        {
          target: `<note>:${v}`,
        },
        (r) => {
        }
      );
    }, 1000);
  }

  const handleQuickNoteBlur = (e) => {
    console.log("XX :: ", quickNoteRef.current.innerText.trim().length)
    if (quickNoteRef.current && quickNoteRef.current.innerText.trim().length > 0) {

    }
    else {
      setQuickNotePlaceholderIsVisible(true);
    }
  }

  const handleFocusQuickNote = (e) => {
    setQuickNotePlaceholderIsVisible(false);
  }

  useEffect(() => {
    if (!quicknotePlaceholderIsVisible) {
      if (quickNoteRef.current) {
        if (skCtx.quick_note) {
          quickNoteRef.current.innerHTML = skCtx.quick_note.val;
        }
        quickNoteRef.current.focus();
      }
    }
  }, [quicknotePlaceholderIsVisible, skCtx.quick_note]);

  useEffect(() => {
    console.log("HTML :: ", skCtx.active_html)
  }, [skCtx.active_html]);

  useEffect(() => {
    if (skCtx.active_note) {

    }
  }, [skCtx.active_note]);

  return (
    <>
      {skCtx.collection_loading ? <div style={{ width: "100%", textAlign: "center" }}>
        <div className={("sk-ldr --purple")}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div> : <>
        {skCtx.active_widget && skCtx.active_widget.key && <div className="search-public-widgets">
          <iframe onLoad={handleLoad} ref={widgetIframeRef} height={skCtx.active_widget.meta["height"] || 150} src={`${skCtx.active_widget.src}`}></iframe>
        </div>}
        {skCtx.active_note && <div className="search-public-widgets" style={{
          maxHeight: 320,
          padding: 16,
          overflow: "auto"
        }}>
          <p contentEditable="true" autoFocus={true} onInput={handleEditActiveNote}>{(skCtx.active_note.target || "").substring(7, skCtx.active_note.target.length)}</p>
        </div>}
        {skCtx.quick_note && <div className="search-public-widgets" style={{
          maxHeight: 320,
          padding: 16,
          minHeight: 58,
          overflow: "auto"
        }} onClick={handleFocusQuickNote}>
          {/* <p ref={quickNoteRef} contentEditable="true" onInput={handleEditQuickNote}>{skCtx.quick_note.val}</p> */}
          {(!quicknotePlaceholderIsVisible) ? <p ref={quickNoteRef} contentEditable="true" onInput={handleEditQuickNote} onBlur={handleQuickNoteBlur}>{ }</p> : <>{quicknotePlaceholderIsVisible ? <p ref={placehodlerOfQuickNoteRef} style={{ opacity: 0.3 }} contentEditable="false">{"Quick note"}</p> : <></>}</>}
        </div>}
        {skCtx.active_html && skCtx.active_html.item && <div className="search-public-widgets" style={{
          maxHeight: '100%',
          height: 400,
          padding: 4,
          overflow: "auto"
        }}>
          <iframe onLoad={handleLoad} ref={widgetIframeRef} height={400} srcDoc={`${skCtx.active_html.item.target.substring(7, skCtx.active_html.item.target.length)}`}></iframe>
        </div>}
        {!((skCtx.active_widget && skCtx.active_widget.key) || skCtx.active_note || skCtx.quick_note || (skCtx.active_html && skCtx.active_html.item)) && <>
          {skCtx.finder_suggestions.eval_result && <div className="search-inbuilt-widgets calc-result">
            <div className="inner">
              <span>{`${skCtx.finder_suggestions.eval_result}`}</span>
              <span onClick={handleCopyEvalResult} className="calc-copy-btn">{copied ? "Copied" : <CopyIcon />}</span>
            </div>
          </div>}
          {skCtx.finder_suggestions.list &&
            skCtx.finder_suggestions.list.map((sugg, i) => {
              return (
                <div
                  key={`${i}`}
                  className={
                    "shortsSuggestion" +
                    (skCtx.finder_navigation.active_index === i ? " active" : "")
                  }
                  onClick={handleSugg(sugg, i)}
                  tabIndex={"0"}
                  onMouseOver={handleMouseOver(i)}
                  onKeyDown={(e) => handleKeyDown(sugg, i)}
                >
                  <div className="inner">
                    <div>
                      <span>
                        {sugg.type === "add"
                          ? "Collect"
                          : sugg.type === "random"
                            ? "Random"
                            : sugg.type === "open"
                              ? "Open URL"
                              : sugg.type === "ask" ? "Ask AI" : sugg.type === "fix_grammar" ? "Optimize" : sugg.title}
                      </span>
                    </div>
                    <div className="auto"></div>
                  </div>
                </div>
              );
            })}
        </>}
      </>}
    </>
  );
}

const Keymo = (props) => (
  <svg
    width={38}
    height={49}
    viewBox="0 0 38 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.676182 21.002C0.676182 12.0393 7.94187 4.77363 16.9046 4.77363H20.5897C29.5524 4.77363 36.8181 12.0393 36.8181 21.002V22.6248C36.8181 24.4921 35.3044 26.0058 33.4372 26.0058H4.05709C2.18987 26.0058 0.676182 24.4921 0.676182 22.6248V21.002Z"
      fill="#542E60"
      stroke="#542E60"
      strokeWidth={1.35236}
    />
    <mask
      id="path-2-outside-1_93_3027"
      maskUnits="userSpaceOnUse"
      x={12.877}
      y={0}
      width={12}
      height={6}
      fill="black"
    >
      <rect fill="white" x={12.877} width={12} height={6} />
      <path d="M14.877 5.65138C14.877 3.63478 16.5117 2 18.5283 2H19.0017C21.0183 2 22.653 3.63478 22.653 5.65138H14.877Z" />
    </mask>
    <path
      d="M14.877 5.65138C14.877 3.63478 16.5117 2 18.5283 2H19.0017C21.0183 2 22.653 3.63478 22.653 5.65138H14.877Z"
      fill="#542E60"
    />
    <path
      d="M13.5246 5.65138C13.5246 2.88789 15.7648 0.647636 18.5283 0.647636H19.0017C21.7652 0.647636 24.0054 2.88789 24.0054 5.65138L21.3007 5.65138C21.3007 4.38167 20.2714 3.35236 19.0017 3.35236H18.5283C17.2586 3.35236 16.2293 4.38167 16.2293 5.65138L13.5246 5.65138ZM22.653 5.65138H14.877H22.653ZM13.5246 5.65138C13.5246 2.88789 15.7648 0.647636 18.5283 0.647636L18.5283 3.35236C17.2586 3.35236 16.2293 4.38167 16.2293 5.65138V5.65138H13.5246ZM19.0017 0.647636C21.7652 0.647636 24.0054 2.88789 24.0054 5.65138H21.3007V5.65138C21.3007 4.38167 20.2714 3.35236 19.0017 3.35236L19.0017 0.647636Z"
      fill="#542E60"
      mask="url(#path-2-outside-1_93_3027)"
    />
    <path
      d="M10.9547 16.8764C7.78038 16.8764 5.20712 19.4497 5.20712 22.624V35.911C5.20712 42.4089 10.4747 47.6765 16.9727 47.6765H20.9284C27.4263 47.6765 32.6939 42.4089 32.6939 35.911V22.624C32.6939 19.4497 30.1207 16.8764 26.9464 16.8764H10.9547Z"
      fill="white"
      stroke="#542E60"
      strokeWidth={1.35236}
    />
    <path
      d="M9.7041 28.9482C11.4361 31.0236 15.7204 30.6399 16.4496 28.9482"
      stroke="#542E60"
      strokeWidth={1.69046}
      strokeLinecap="round"
    />
    <path
      d="M21.0796 28.9454C22.8115 31.0209 27.0958 30.6372 27.8251 28.9454"
      stroke="#542E60"
      strokeWidth={1.69046}
      strokeLinecap="round"
    />
    <path
      d="M15.2139 40.0028C16.6134 41.1674 20.4762 41.2713 22.3138 40.0028"
      stroke="#542E60"
      strokeWidth={1.69046}
      strokeLinecap="round"
    />
    <mask
      id="path-8-outside-2_93_3027"
      maskUnits="userSpaceOnUse"
      x={32.9302}
      y={25.2219}
      width={4}
      height={15}
      fill="black"
    >
      <rect fill="white" x={32.9302} y={25.2219} width={4} height={15} />
      <path d="M32.9302 28.2362C32.9302 27.676 33.3843 27.2219 33.9444 27.2219H34.1135C34.6737 27.2219 35.1278 27.676 35.1278 28.2362V36.9927C35.1278 37.5529 34.6737 38.007 34.1135 38.007H33.9444C33.3843 38.007 32.9302 37.5529 32.9302 36.9927V28.2362Z" />
    </mask>
    <path
      d="M32.9302 28.2362C32.9302 27.676 33.3843 27.2219 33.9444 27.2219H34.1135C34.6737 27.2219 35.1278 27.676 35.1278 28.2362V36.9927C35.1278 37.5529 34.6737 38.007 34.1135 38.007H33.9444C33.3843 38.007 32.9302 37.5529 32.9302 36.9927V28.2362Z"
      fill="white"
    />
    <path
      d="M32.9302 27.6445C32.9302 26.6642 33.7249 25.8695 34.7052 25.8695H34.7052C35.6854 25.8695 36.4801 26.6642 36.4801 27.6445L33.7754 28.5742H33.9444C33.3843 28.5742 32.9302 28.5742 32.9302 28.5742V27.6445ZM36.4801 37.5844C36.4801 38.5647 35.6854 39.3593 34.7052 39.3593H34.7052C33.7249 39.3593 32.9302 38.5647 32.9302 37.5844V36.6546C32.9302 36.6546 33.3843 36.6546 33.9444 36.6546H33.7754L36.4801 37.5844ZM32.9302 38.007V27.2219V38.007ZM34.7052 25.8695C35.6854 25.8695 36.4801 26.6642 36.4801 27.6445V37.5844C36.4801 38.5647 35.6854 39.3593 34.7052 39.3593L33.9444 38.007C33.8878 38.007 33.8313 38.0022 33.7754 37.9928L33.7754 28.2362V27.2359C33.8313 27.2266 33.8878 27.2219 33.9444 27.2219L34.7052 25.8695Z"
      fill="#542E60"
      mask="url(#path-8-outside-2_93_3027)"
    />
    <mask
      id="path-10-outside-3_93_3027"
      maskUnits="userSpaceOnUse"
      x={0.772949}
      y={25.2219}
      width={5}
      height={15}
      fill="black"
    >
      <rect fill="white" x={0.772949} y={25.2219} width={5} height={15} />
      <path d="M2.77295 28.2362C2.77295 27.676 3.22705 27.2219 3.78722 27.2219H3.95627C4.51643 27.2219 4.97054 27.676 4.97054 28.2362V36.9927C4.97054 37.5529 4.51644 38.007 3.95627 38.007H3.78722C3.22706 38.007 2.77295 37.5529 2.77295 36.9927V28.2362Z" />
    </mask>
    <path
      d="M2.77295 28.2362C2.77295 27.676 3.22705 27.2219 3.78722 27.2219H3.95627C4.51643 27.2219 4.97054 27.676 4.97054 28.2362V36.9927C4.97054 37.5529 4.51644 38.007 3.95627 38.007H3.78722C3.22706 38.007 2.77295 37.5529 2.77295 36.9927V28.2362Z"
      fill="white"
    />
    <path
      d="M1.42059 27.6445C1.42059 26.6642 2.21527 25.8695 3.19556 25.8695H3.19556C4.17586 25.8695 4.97054 26.6642 4.97054 27.6445V28.5742C4.97054 28.5742 4.51644 28.5742 3.95627 28.5742H4.12531L1.42059 27.6445ZM4.97054 37.5844C4.97054 38.5647 4.17586 39.3593 3.19556 39.3593H3.19556C2.21527 39.3593 1.42059 38.5647 1.42059 37.5844L4.12531 36.6546H3.95627C4.51644 36.6546 4.97054 36.6546 4.97054 36.6546V37.5844ZM3.19556 39.3593C2.21527 39.3593 1.42059 38.5647 1.42059 37.5844V27.6445C1.42059 26.6642 2.21527 25.8695 3.19556 25.8695L3.95627 27.2219C4.0129 27.2219 4.06944 27.2266 4.12529 27.2361L4.12531 28.2362V37.993C4.06944 38.0022 4.0129 38.007 3.95627 38.007L3.19556 39.3593ZM4.97054 27.2219V38.007V27.2219Z"
      fill="#542E60"
      mask="url(#path-10-outside-3_93_3027)"
    />
    <path
      d="M3.47979 27.5445C3.48768 27.5062 3.50138 27.4731 3.52794 27.4444C3.64195 27.3212 4.03357 27.0229 4.87963 27.4902C5.91254 28.0607 6.16653 26.5003 6.26813 27.4902C6.36972 28.4802 8.01222 37.0541 6.26813 36.7185C4.52403 36.383 4.87962 39.4702 3.49113 36.7185C2.17711 34.1144 3.35018 28.1742 3.47979 27.5445Z"
      fill="white"
    />
    <path
      d="M34.4206 27.5114C34.4127 27.4731 34.399 27.4399 34.3724 27.4112C34.2584 27.288 33.8668 26.9898 33.0208 27.4571C31.9879 28.0276 31.7339 26.4671 31.6323 27.4571C31.5307 28.447 29.8882 37.021 31.6323 36.6854C33.3764 36.3498 33.0208 39.4371 34.4093 36.6854C35.7233 34.0813 34.5502 28.1411 34.4206 27.5114Z"
      fill="white"
    />
  </svg>
);

const CopyIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_93_3068"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <rect width={24} height={24} fill="#5D366D" />
    </mask>
    <g mask="url(#mask0_93_3068)">
      <path
        d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6H5V20H16V22H5ZM9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16Z"
        fill="#5D366D"
      />
    </g>
  </svg>
);

function SuggestionContainer({ aiActivated, aiAnswer, grammarMode, showAIAnswer }) {
  const suggRef = useRef(null);
  const skCtx = useSk();
  const [copied, setCopied] = useState(false);
  const aiInputRef = useRef(null);

  const [aiFollowUpLoading, setAIFollowUpLoading] = useState(false);

  useOnClickOutside(
    suggRef,
    (e) => {
      // hide suggestion box
    },
    (e) => { },
    skCtx.finder.visible
  );

  function removeHtmlTags(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  const handleCopy = (e) => {
    navigator.clipboard.writeText(removeHtmlTags(aiAnswer)).then(() => {
      setCopied(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  useEffect(() => {
    if (aiAnswer && aiActivated) {
      if (aiInputRef.current) {
        /** Auto Scroll */
        let el = document.querySelector(".mainContentWrapper");
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
        aiInputRef.current.focus();
      }
    }
  }, [aiAnswer, aiFollowUpLoading]);

  useEffect(() => {
    if (aiInputRef.current) {
      aiInputRef.current.focus();
    }
  }, [skCtx.ai_follow_ups]);

  useEffect(() => {
    if (aiActivated) {
      let d = document.querySelector("#grammar-tool-input");
      if (d) {
        let k = aiAnswer || "";
        k = k.replace(/\n/g, "<br>"); // Replace \n with <br> globally
        d.innerHTML = k; // Set the modified string with <br> tags
      }
    }
  }, [aiAnswer, aiActivated]);

  const handleKeyDownFollowUpAI = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let v = (e.target.innerText || "").trim();
      if (v.length > 0) {
        setAIFollowUpLoading(true);
        await skCtx.followUpAIConvo(v);
        e.target.innerText = '';
        aiInputRef.current.innerText = '';
        e.target.focus();
        setAIFollowUpLoading(false);
      }
    }
  }

  const handleFollowUpAI = (e) => {
    let v = (e.target.innerText || "").trim();
  }

  if (skCtx.finder.loading) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <div className={("sk-ldr --purple")}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    );
  }

  const handleModifyChat = (i) => (e) => {
    let new_convo = [...skCtx.ai_follow_ups];
    new_convo = new_convo.slice(0, i);
    skCtx.setAIConversations(new_convo);
    if (aiInputRef.current) {
      aiInputRef.current.innerText = skCtx.ai_follow_ups[i].content;
    }
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const text = (e.clipboardData || window.clipboardData).getData('text');
    document.execCommand('insertText', false, text);
  }


  return (
    <>
      {skCtx.finder_suggestions.visible && (
        <div ref={suggRef} className="suggestionsContainer">
          <div>
            {aiActivated ? <>
              {/* <span>Wait for an</span> */}
              {aiAnswer ? (<>

                <div className="ai-result-follow-up -assistant">
                  <span className="keymo">
                    {/* <Keymo /> */}
                  </span>
                  <span className="answer-text"><p id="grammar-tool-input"></p>
                    {grammarMode && <span onClick={handleCopy} className="grammar-mode-tool">
                      {copied ? "Copied" : <CopyIcon />}
                    </span>}
                  </span>
                </div>

                {skCtx.ai_follow_ups.map((convo, i) => {
                  return (
                    <div key={`${i}`} className={("ai-result-follow-up") + (convo.role === "assistant" ? " -assistant" : " -user")}>
                      <span className="answer-text">{convo.content}</span>
                      {convo.role === "user" && <span onClick={handleModifyChat(i)} className="edit-icon"><PencilIcon /></span>}
                    </div>
                  );
                })}

                <div className="ai-result-follow-up -input">
                  <span onPaste={handlePaste} onKeyDown={handleKeyDownFollowUpAI} onKeyUp={handleFollowUpAI} ref={aiInputRef} contentEditable={true} className="input-container"></span>
                </div>

                {aiFollowUpLoading && <div style={{ width: "100%", textAlign: "center" }}>
                  <div className={("sk-ldr --purple")}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>}

                <div style={{ marginBottom: 200, width: '100%' }}></div>

                {/* <div className="ai-result-follow-up -user">
                <span className="answer-text">{"aiAnswer"}</span>
                </div>
                <div className="ai-result-follow-up -assistant">
                <span className="answer-text">{"aiAnswer"}</span>
                </div>
                <div className="ai-result-follow-up -user">
                <span className="answer-text">{"aiAnswer"}</span>
                </div> */}

              </>) : <div style={{ width: "100%", textAlign: "center" }}>
                <div className={("sk-ldr --purple")}>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>}
            </> : <SuggestionHints showAIAnswer={showAIAnswer} />}
          </div>
        </div>
      )}
    </>
  );
}

function ShortsLauncher() {
  const skCtx = useSk();
  const authCtx = useAuth();
  const [multilineActive, setMultilineActive] = useState(false);
  const [aiActivated, setAIActivated] = useState(false);
  const [aiAnswer, setAIAnswer] = useState(null);
  const [grammarMode, setGrammarMode] = useState(false);

  const handleShowAIAnswer = async (question, grammar_fixer, status) => {
    // if (status) {
    //   setAIActivated(true);
    //   await InitService.askAI(question, {}).then((r) => {
    //     if (r.status && r.status === 200) {
    //       if (grammar_fixer) {
    //         setAIAnswer(`GR : ${r.data.answer}`);
    //         setGrammarMode(true);
    //       }
    //       else {
    //         setAIAnswer(r.data.answer);
    //         setGrammarMode(false);
    //       }
    //     }
    //     else {
    //       setAIAnswer("failed to fetch.");
    //     }
    //   });
    // }
    // else {
    //   setAIActivated(false);
    //   setAIAnswer(null);
    // }
  }

  useEffect(() => {
    console.log("Diff :: ", skCtx.finder, skCtx['categories'])
  }, [skCtx.finder.user_query, skCtx['categories']]);
  

  return (
    <>
      <div
        className={
          "shortsLauncherInputContainer" +
          (multilineActive ? " activeMultiline" : "")
        }
      >
        <InitialShortkeyInput showAIAnswer={handleShowAIAnswer} onMultilineAcitve={(v) => setMultilineActive(v)} />
      </div>
      {skCtx.finder_suggestions.visible || skCtx.finder.is_active || (skCtx.finder.user_query && skCtx.finder.user_query.length > 0) ? <></> : (
        skCtx.setting['categories'] ? <CategoriesList /> : <></>
      )}
      {skCtx.setting["suggest"] && <SuggestionContainer showAIAnswer={handleShowAIAnswer} aiActivated={['ask', 'fix_grammar'].includes(skCtx.finder_suggestions.ai_mode)} aiAnswer={skCtx.finder_suggestions.ai_result} grammarMode={skCtx.finder_suggestions.ai_mode === 'fix_grammar'} />}
      {skCtx.activate_mobile_view ? "" : skCtx.finder_suggestions.visible === false &&
        authCtx.account.signed === false ? (
        <>
          {/* {skCtx.setting['minimalistic'] ? <></> : <div style={{ marginTop: 10 }} className="early-suggestion">
            <a
              target="_blank"
              className="graybtn"
              href="https://youtu.be/17Zxi2BMGgk"
              rel="noreferrer"
            >
              Learn how to use Shortkey.
            </a>
          </div>} */}
        </>
      ) : (
        ""
      )}
    </>
  );
}

function ShortsLauncherContainer({ where = "_inMainPage" }) {

  const skCtx = useSk();
  const uiCtx = useUI();
  const [mobileViewStage, setMobileViewStage] = useState(0);

  useEffect(() => {
    if (isChrome()) {
      let stup = localStorage.getItem("is_setup");
      if (!stup) {
        uiCtx.setDialog(true, DialogTabList.ExtensionSetup);
      }
    }
  }, []);

  useEffect(() => {
    if (skCtx.mobile_view) {
      setTimeout(() => {
        setMobileViewStage(1);
      }, 200);
    }
  }, [skCtx.mobile_view]);

  return (
    <>
      {!skCtx.activate_mobile_view && <div className={(`launcherArea ${where}`) + (skCtx.mobile_view ? ` -mobile-view__IGNORED -stage-${mobileViewStage}__IGNORED` : "") + (['ask', 'fix_grammar'].includes(skCtx.finder_suggestions.ai_mode) ? " --allow-scroll" : "")}>
        {skCtx.setting['minimalistic'] ? <></> : <Logo />}
        <ShortsLauncher />
      </div>}
      {skCtx.activate_mobile_view && <div
        className={("skey-mobile-view-launcher launcherArea") + (skCtx.activate_mobile_view ? ` -mobile-view` : '') + (['ask', 'fix_grammar'].includes(skCtx.finder_suggestions.ai_mode) ? " --allow-scroll" : "")}>
        <ShortsLauncher />
      </div>}
    </>
  );
}

export default ShortsLauncherContainer;
