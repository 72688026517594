export const CookiesList = {
    AccessToken: 'access_tk',
    EssentialCookies: "_es",
    OtherCookies: "_ot",
    AnalyticalCookies: "_at",
    CookiesDeployed: "_cd"
};

export const CookiesArray = [
    CookiesList.AccessToken,
    CookiesList.EssentialCookies,
    CookiesList.OtherCookies,
    CookiesList.AnalyticalCookies,
    CookiesList.CookiesDeployed
]

export const LocalStorageKeys = {
    LocalAccountJoinedAt: 'local_j',
    DetectedRegion: 'ip_reg',
    SavedRemoteAccount: 'sv_account',
    SettingsUpdated: 'settings_updated_at'
}

export const defaultCookies = {
    essential: true,
    other: true,
    analytical: false
}

// export const defaultSettings = {

// }