import shortkeys_list from "../data/shortkeys.json"
import defaultSearchOptions from "../data/default_engines.json"

export const getUserItem = () => {
  return shortkeys_list;
};

// Function to retrieve items from localStorage
const getItemsFromLocalStorage = () => {
  const itemsJSON = localStorage.getItem("items");
  if (!itemsJSON) {
    saveItemsToLocalStorage(getUserItem());
    const itemsJSONNew = localStorage.getItem("items");
    return itemsJSONNew ? JSON.parse(itemsJSONNew) : [];
  }
  return itemsJSON ? JSON.parse(itemsJSON) : [];
};

// Function to save items to localStorage
const saveItemsToLocalStorage = (items) => {
  localStorage.setItem("items", JSON.stringify(items));
};

const query = async (where, offset, limit) => {
  const items = getItemsFromLocalStorage();
  return items
    .filter((item) => {
      for (const key in where) {
        if (where[key] !== item[key]) {
          return false;
        }
      }
      return true;
    })
    .sort((a, b) => a.order - b.order);
};

const insertBulk = async (data) => {
  const items = getItemsFromLocalStorage();
  items.push(...data);
  saveItemsToLocalStorage(items);
  return data.length;
};

const upsertItemWithOrder = (
  isBelow,
  itemId,
  title,
  shortkeys,
  target,
  pinned,
  newOrder
) => {
  try {
    let items = getItemsFromLocalStorage();
    let item;
    let maxOrder = items.reduce((max, item) => Math.max(max, item.order), 0);

    if (itemId) {
      item = items.find((item) => item._id === itemId);
      if (!item) {
        throw new Error("Item not found.");
      }
    } else {
      if (!isBelow) {
        // Increment order of existing items
        items.forEach((item) => item.order++);
        newOrder = 1;
      } else {
        // Do nothing for existing items
      }
    }

    if (newOrder !== undefined) {
      if (item) {
        if (item.order !== newOrder) {
          const increment = item.order > newOrder ? 1 : -1;
          items.forEach((otherItem) => {
            if (
              otherItem.order >= Math.min(item.order, newOrder) &&
              otherItem.order <= Math.max(item.order, newOrder) &&
              otherItem._id !== item._id // Exclude the item being updated
            ) {
              otherItem.order += increment;
            }
          });
          item.order = newOrder;
        }
      } else {
        if (!isBelow) {
          maxOrder = newOrder - 1;
        } else {
          // Increment order of existing items with order >= newOrder
          items.forEach((item) => {
            if (item.order >= newOrder) {
              item.order++;
            }
          });
        }
      }
    }

    let updatedItem = null;

    if (item) {
      if (title !== undefined) item["title"] = title;
      if (shortkeys !== undefined) item["shortkeys"] = shortkeys;
      if (target !== undefined) item["target"] = target;
      if (pinned !== undefined) item["pinned"] = pinned;
      if (newOrder !== undefined) item["order"] = newOrder;
      updatedItem = item;
    } else {
      const newItemOrder = isBelow ? maxOrder + 1 : 1;
      updatedItem = {
        _id: Math.random().toString(36).substr(2, 9),
        //   userId, // Add userId if needed
        title: title,
        shortkeys: shortkeys,
        target: target,
        order: newOrder !== undefined ? newOrder : newItemOrder,
        pinned: pinned,
      };
      items.push(updatedItem);
    }

    saveItemsToLocalStorage(items);
    return updatedItem;
  } catch (error) {
    console.error("Error upserting item:", error);
    throw error;
  }
};

const remove = async (id) => {
  try {
    let items = getItemsFromLocalStorage();
    const itemIndex = items.findIndex((item) => item._id === id);
    if (itemIndex === -1) {
      throw new Error("Item not found.");
    }

    items.splice(itemIndex, 1);

    items.forEach((item) => {
      console.log("i : ", item, itemIndex);
      if (item.order > items[itemIndex] && items[itemIndex].order) {
        item.order--;
      }
    });

    saveItemsToLocalStorage(items);
  } catch (error) {
    console.error("Error removing item:", error);
    throw error;
  }
};

const save = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};
// const get = async (key) =>
  // localStorage.getItem(key) ?  : null;

const get = async (key) => {
  let l = localStorage.getItem(key);
  try {
    let x = JSON.parse(localStorage.getItem(key));
    return x;
  } catch (error) {
    return null;
  }
  return null;
}

export {
  defaultSearchOptions,
  get,
  insertBulk,
  query,
  remove,
  save,
  upsertItemWithOrder,
};
