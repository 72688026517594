import * as React from "react";
import Icons from ".";
const AddIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.0081 13.0089L13.0081 21.8869C13.0081 22.1655 12.9096 22.4033 12.7127 22.6003C12.5157 22.7973 12.2779 22.8957 11.9993 22.8957C11.7206 22.8957 11.4828 22.7973 11.2858 22.6003C11.0889 22.4033 10.9904 22.1655 10.9904 21.8869L10.9904 13.0088L2.11237 13.0088C1.83374 13.0089 1.59593 12.9104 1.39896 12.7134C1.202 12.5165 1.10352 12.2786 1.10352 12C1.10352 11.7214 1.202 11.4835 1.39896 11.2866C1.59593 11.0896 1.83374 10.9911 2.11237 10.9911L10.9904 10.9911L10.9904 2.11311C10.9904 1.83448 11.0889 1.59668 11.2858 1.3997C11.4828 1.20274 11.7206 1.10426 11.9993 1.10426C12.2779 1.10426 12.5157 1.20274 12.7127 1.3997C12.9096 1.59668 13.0081 1.83448 13.0081 2.11311L13.0081 10.9911L21.8861 10.9911C22.1648 10.9911 22.4026 11.0896 22.5996 11.2866C22.7965 11.4835 22.895 11.7214 22.895 12C22.895 12.2786 22.7965 12.5165 22.5996 12.7134C22.4026 12.9104 22.1648 13.0089 21.8861 13.0089L13.0081 13.0089Z"
            fill={props.color || Icons.IconColors.idle}
        />
    </svg>
);
export default AddIcon;
