import { upsertItemWithOrder, query, remove } from "./Item.store.new";

const getItems = async (offset = 0, limit = 99999) => {
  try {
    let items = await query({});
    return {
      data: {
        offset: parseInt(offset),
        limit: parseInt(limit),
        count: items.length,
        data: items,
      },
    };
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

const createOrUpdate = async (
  itemId,
  { title, shortkeys, target, order, pinned },
  isBelow = false
) => {
    console.log("isBelo :: ", isBelow)
  try {
    const createResponse = await upsertItemWithOrder(
      isBelow,
      itemId,
      title,
      shortkeys,
      target,
      pinned,
      order
    );
    const updatedList = await query({});
    return {
      data: {
        updates: {
          offset: 0,
          limit: 10,
          count: updatedList.length,
          data: updatedList,
        },
        operation_result: createResponse,
      },
    };
  } catch (error) {
    console.error("Error creating/updating item:", error);
    throw error;
  }
};

const deleteOne = async (itemId) => {
  try {
    const delResponse = await remove(itemId);
    const updatedList = await query({});
    return {
      data: {
        updates: {
          offset: 0,
          limit: 10,
          count: updatedList.length,
          data: updatedList,
        },
        operation_result: delResponse,
      },
    };
  } catch (error) {
    console.error("Error deleting item:", error);
    throw error;
  }
};

export default {
  getItems,
  deleteOne,
  createOrUpdate,
};
