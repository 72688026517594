import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { ButtonIcon, ButtonText } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";

export default function DialogCookiesTab() {

    const authCtx = useAuth();
    const uiCtx = useUI();

    const handleCheck = (cookieType) => (e) => {
        authCtx.setCookiesAllowed({
            [cookieType]: e.target.checked
        });
    }

    const onCloseClick = (e) => {
        uiCtx.setDialog(false);
    }

    return (
        <>
            <div
                className="dialog_col titlebar common-w">
                    <div>
                        <ButtonIcon onClick={() => {window.history.back()}}>
                            <Icons.BackIcon color={Icons.IconColors.active} />
                        </ButtonIcon>
                    </div>
                    <div>
                        <span className="jsTextPrimary">Cookies setup</span>
                    </div>
                    <div>
                        <ButtonIcon onClick={onCloseClick}>
                            <Icons.CloseIcon color={Icons.IconColors.active} />
                        </ButtonIcon>
                    </div>
            </div>
            <div
                className="dialog_col center-layout pd12-24">
                    <CheckboxControl onChange={handleCheck("essential")} label={"Essential cookies"} jsText={true} desc={"enable authentification, navigation, and other core functions. Disabling such cookies may affect how Shortkey websites and services technically operate. That's why essential cookies are enabled by default."} disabled={true} isChecked={authCtx.cookies.essential} />
            </div>
            <div
                className="dialog_col center-layout pd12-24">
                    <CheckboxControl onChange={handleCheck("other")} label={"Other cookies"} jsText={true} desc={"are auxiliary, they help Shortkey websites and services work more efficiently for your convenience. These cookies help to restore web page sessions, remember preferred region, save personal preferences, and more"} disabled={false} isChecked={authCtx.cookies.other} />
            </div>
            <div
                className="dialog_col center-layout pd12-24 mb-12">
                    <CheckboxControl onChange={handleCheck("analytical")} label={"Analytical cookies"} jsText={true} desc={" improve experience of working on Shortey services. They remember preference settings, anonymously analyze website traffic, and help displaying relevant ads."} disabled={false} isChecked={authCtx.cookies.analytical} />
            </div>
            <div
                className="dialog_col center-layout mb-12">
                    <p className="jsTextBody start-dialog-desc-text">Learn more about <a className="jsTextBody underline" href="#privacy">Privacy</a> and <a className="jsTextBody underline" href="#terms">Terms</a>.</p>
            </div>
            <div
                className="dialog_col center-layout pd-12">
            </div>
        </>
    );
}