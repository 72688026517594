import { apiRoutes } from ".";
import { callApi } from "./caller";

export default class InitService {

    static async initialize(
        req = {
            cancelToken: undefined,
            signal: undefined
        },
        isInitialized
    ) {
        return await callApi({
            method: 'get',
            path: apiRoutes.init.initialize+'?initialized='+(isInitialized ? '1' : '0'),
            cancelToken: req.cancelToken,
            signal: req.signal
        });
    }

    static async askAI(
        {p, question, mode},
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'post',
            path: 'user'+'/ask',
            cancelToken: req.cancelToken,
            signal: req.signal,
            payload: {
                p: p ? p : null,
                question: question,
                mode: mode
            }
        });
    }

    static async getLoginToken(req = {
        cancelToken: undefined,
        signal: undefined
    }) {
        return await callApi({
            method: 'post',
            path: 'auth'+'/login_bk',
            cancelToken: req.cancelToken,
            signal: req.signal,
            payload: {}
        });
    }

    static async followUp({convo}, req = {
        cancelToken: undefined,
        signal: undefined
    }) {
        return await callApi({
            method: 'post',
            path: 'user'+'/follow_up',
            cancelToken: req.cancelToken,
            signal: req.signal,
            payload: {
                convo: convo
            }
        });
    }

    static async searchNotes({query}, req = {
        cancelToken: undefined,
        signal: undefined
    }) {
        return await callApi({
            method: 'post',
            path: 'user'+'/search_notes',
            cancelToken: req.cancelToken,
            signal: req.signal,
            payload: {
                query: query
            }
        });
    }

    static async fetchPlusCollectionsOnline() {
        let k = await fetch("https://shortkey.org/data/collections.json");
        // let k = await fetch("http://localhost:3000/data/collections.json");
        let j = await k.json();
        localStorage.setItem("plus_collections", JSON.stringify(j));
        return j;
    }

    static async fetchPlusCollections() {
        let va = localStorage.getItem("plus_collections");
        if(!va) {
            return await InitService.fetchPlusCollectionsOnline();
        }
        try {
            let va= localStorage.getItem("plus_collections");
            let v = JSON.parse(va);
            if(v && v.length > 0) {
                setTimeout(() => {
                    InitService.fetchPlusCollectionsOnline();
                }, 1000);
                return v;
            }
        } catch (error) {
            return await InitService.fetchPlusCollectionsOnline();
        }
    }

    static async fetchPlusCollectionValuesOnline() {
        let k = await fetch("https://shortkey.org/data/collection_keys.json");
        // let k = await fetch("http://localhost:3000/data/collection_keys.json");
        let j = await k.json();
        // localStorage.setItem("plus_collections_values", JSON.stringify(j));
        return j;
    }

    static async fetchPlusCollectionValues() {
        // let va = localStorage.getItem("plus_collections_values");
        // if(!va) {
            return await InitService.fetchPlusCollectionValuesOnline();
        // }
        // try {
        //     let va= localStorage.getItem("plus_collections_values");
        //     let v = JSON.parse(va);
        //     if(v && v.length > 0) {
        //         setTimeout(() => {
        //             InitService.fetchPlusCollectionValuesOnline();
        //         }, 1000);
        //         return v;
        //     }
        // } catch (error) {
        //     return await InitService.fetchPlusCollectionValuesOnline();
        // }
    }

    static async fetchGlobalListDataOnline() {
        // let k = await fetch("https://shortkey.org/data/global_keys.json");
        // // let k = await fetch("http://localhost:3000/data/global_keys.json");
        // let j = await k.json();
        // localStorage.setItem("global_list", JSON.stringify(j));
        // return j;

        let k = await fetch("https://shortkey.org/data/output.csv");
        // let k = await fetch("http://localhost:3000/data/output.csv");
        let j = await k.text();
        localStorage.setItem("global_list_csv", j);
        return j;
    }

    static async fetchGlobalListData() {
        // let va = localStorage.getItem("global_list");
        // if(!va) {
        //     return await InitService.fetchGlobalListDataOnline();
        // }
        // try {
        //     let v = JSON.parse(va);
        //     if(v && v.length > 0) {
        //         setTimeout(() => {
        //             InitService.fetchGlobalListDataOnline();
        //         }, 1000);
        //         return v;
        //     }
        // } catch (error) {
        //     return await InitService.fetchGlobalListDataOnline();
        // }
        let va = localStorage.getItem("global_list_csv");
        if(!va) {
            return await InitService.fetchGlobalListDataOnline();
        }
        try {
            let v = va;
            if(v && v.length > 0) {
                setTimeout(() => {
                    InitService.fetchGlobalListDataOnline();
                }, 1000);
                return v;
            }
        } catch (error) {
            return await InitService.fetchGlobalListDataOnline();
        }
    }

    static async fetchGlobalSuggestionDataOnline() {
        let k = await fetch("https://shortkey.org/data/global_sugg.json");
        // let k = await fetch("http://localhost:3000/data/global_sugg.json");
        let j = await k.json();
        localStorage.setItem("global_sugg", JSON.stringify(j));
        return j;
    }

    static async fetchGlobalSuggestionData() {
        let va = localStorage.getItem("global_sugg");
        if(!va) {
            return await InitService.fetchGlobalSuggestionDataOnline();
        }
        try {
            let va= localStorage.getItem("global_sugg");
            let v = JSON.parse(va);
            if(v && v.length > 0) {
                setTimeout(() => {
                    InitService.fetchGlobalSuggestionDataOnline();
                }, 1000);
                return v;
            }
        } catch (error) {
            return await InitService.fetchGlobalSuggestionDataOnline();
        }
    }

}