import { apiRoutes } from ".";
import { callApi } from "./caller";

export default class ItemService {
   
    static async initialize(
        offset,
        limit,
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'get',
            path: `${apiRoutes.item.initialize}?offset=${offset}&limit=${limit}`,
            cancelToken: req.cancelToken,
            signal: req.signal
        })
    }
    
    static async initializeShorts(
        offset,
        limit,
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'get',
            path: `${apiRoutes.short.initialize}?offset=${offset}&limit=${limit}`,
            cancelToken: req.cancelToken,
            signal: req.signal
        })
    }

    static async initializeKeys(
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'get',
            path: apiRoutes.key.initialize,
            cancelToken: req.cancelToken,
            signal: req.signal
        })
    }

    static async putItem(
        id,
        data = {},
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'put',
            path: id ? `${apiRoutes.item.initialize}?id=${id}` : apiRoutes.item.initialize,
            cancelToken: req.cancelToken,
            signal: req.signal,
            payload: data
        })
    }

    static async deleteItem(
        id,
        req = {
            cancelToken: undefined,
            signal: undefined
        }
    ) {
        return await callApi({
            method: 'delete',
            path: id ? `${apiRoutes.item.initialize}?id=${id}` : apiRoutes.item.initialize,
            cancelToken: req.cancelToken,
            signal: req.signal,
        })
    }

}