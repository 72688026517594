import * as React from "react";
import Icons from ".";
const SearchIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M22 22L16.0741 16.0741M10.127 18.254C12.2824 18.254 14.3495 17.3977 15.8736 15.8736C17.3977 14.3495 18.254 12.2824 18.254 10.127C18.254 7.97158 17.3977 5.90444 15.8736 4.38034C14.3495 2.85623 12.2824 2 10.127 2C7.97158 2 5.90444 2.85623 4.38034 4.38034C2.85623 5.90444 2 7.97158 2 10.127C2 12.2824 2.85623 14.3495 4.38034 15.8736C5.90444 17.3977 7.97158 18.254 10.127 18.254Z"
            stroke={props.color || Icons.IconColors.idle}
            strokeWidth={2}
            strokeLinecap="square"
            strokeLinejoin="round"
        />
    </svg>
);
export default SearchIcon;
