import { useEffect, useRef, useState } from "react";
import { DialogTabList, useUI } from "../../contexts/UICtx";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Sidebar from "../Sidebar";
import AddSearchEngineTab from "./addsearchengine.dialog";
import AddShortTab from "./addshort.dialog";
import DialogCookiesTab from "./cookies.dialog";
import DialogPrivacyTab from "./privacy.dialog";
import DialogStartTab from "./start.dialog";
import DialogTermsTab from "./terms.dialog";
import { useSk } from "../../contexts/SkCtx";
import { useAuth } from "../../contexts/AuthCtx";
import ExtensionSetupDialog from "./setupext.dialog";

function Dialog() {

    const authCtx = useAuth();
    const uiCtx = useUI();
    const skCtx = useSk();
    const dialogRef = useRef(null);

    const idRef = useRef(null);
    const [data, setData] = useState({});

    useEffect(() => {
        console.log("DXUUUU :: ", data)
    }, [data]);

    useOnClickOutside(dialogRef, () => {

        if(uiCtx.dialog_tab === DialogTabList.AddShort) {
            // if(uiCtx.data) {
            //     console.log("xx da :: ", uiCtx.data)
            //     // if(uiCtx.data["new_short_data"] && Object.keys(uiCtx.data["new_short_data"]).length > 0) {
            //     //     skCtx.createShortOrKey(uiCtx.data["new_short_data"]);
            //     // }
            //     // else if(uiCtx.data["item_short"] && Object.keys(uiCtx.data["item_short"]).length > 0) {
            //     //     skCtx.updateShort(uiCtx.data["item_short"]._id, uiCtx.data["item_short"]);
            //     // }
            // }

            console.log("DXXXXX:: ", idRef.current, data)

            if (idRef.current) {
                if((data.title || "").length < 1 && (data.url || "").length < 1) {
                  if (idRef.current) {
                    authCtx.deleteItem(idRef.current);
                  }
                }
              }

            skCtx.setCurrentShortkey(null);
        }

        if(uiCtx.dialog_tab === DialogTabList.ExtensionSetup) {

        }
        else
        {
            uiCtx.setDialog(false);
        }
    }, () => {}, uiCtx.dialog_active);

    return (
        <div
            ref={dialogRef}
            className={("dialog-box")+(" "+uiCtx.dialog_tab)}
            style={{overflow: "auto"}}>
                <div>
                    {uiCtx.dialog_tab === DialogTabList.Start ? <DialogStartTab /> : uiCtx.dialog_tab === DialogTabList.Cookies ? <DialogCookiesTab /> : uiCtx.dialog_tab === DialogTabList.Privacy ? <DialogPrivacyTab /> : uiCtx.dialog_tab === DialogTabList.Terms ? <DialogTermsTab /> : uiCtx.dialog_tab === DialogTabList.AddShort ? <AddShortTab data={data} setData={setData} updateIdRef={(id) => {idRef.current = id; console.log("DXXXID :: ", id)}} /> : uiCtx.dialog_tab === DialogTabList.AddSearchEngine ? <AddSearchEngineTab /> : uiCtx.dialog_tab === DialogTabList.ExtensionSetup ? <ExtensionSetupDialog /> : <></>}
                </div>
        </div>
    );
}

function DialogManager() {

    const uiCtx = useUI();

    return (
        <>
            <div className={("dialog-wrapper")+(uiCtx.sidemenu_active ? " active-sidebar" : " hidden-sidebar")}>
                <Sidebar />
            </div>
            {uiCtx.dialog_active && (
                <div
                    className="dialog-wrapper">
                        <Dialog />
                </div>
            )}
        </>
    );
}

export default DialogManager;