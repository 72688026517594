import { useEffect } from "react";
import ShortsLauncherContainer from "../components/Launcher";
import { isChrome } from "../env";
import { useSk } from "../contexts/SkCtx";
import { useAuth } from "../contexts/AuthCtx";

function MainPage() {

    const authCtx = useAuth();
    const skCtx = useSk();

    useEffect(() => {
        if(isChrome()) {
            console.log("is chrome setting ::: ", skCtx.setting);
            if(!skCtx.setting['new_tab']) {
                // window.location.href = `chrome://newtab/`;
                window.location.href = `chrome://new-tab-page`
                return;
            }
            else
            {
                if(skCtx.setting['auto_focus']) {
                    if(window.location.search !== '?x')
                    {
                        window.location.search = '?x';
                        throw new Error;
                    }
                }
            }
        }
    }, []);

    return (
        <>
            <div
                className="mainContentWrapper">
                <div
                    className="mainContentHolder">
                        <ShortsLauncherContainer />
                </div>
                {/* {skCtx.setting['minimalistic'] !== true && authCtx.initialized && authCtx.account && !authCtx.account.signed && <div className="mainContentFooter">
                    <div>
                        <span>Learn more</span>
                    </div>
                </div>} */}
            </div>
        </>
    );
}

export default MainPage;