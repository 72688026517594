// const CheckboxControl = ({ isChecked = false, centerLabel = false, label = null, desc = null, jsText=false, ...props }) => {
//     return (
//         <label className="checkbox-container">

//             <div
//                 className={("label-item") + (centerLabel ? " --center" : "")}>
//                 <div className="label">
//                     <span className={jsText ? "jsTextPrimary jsFontStyle" : "textPrimary fontStyle"}>{label}</span>
//                 </div>
//                 <div className="desc">
//                     <p className={jsText ? "jsTextBody jsFontStyle" : "textBody fontStyle"}>{desc}</p>
//                 </div>
//             </div>

//             <input {...props} type="checkbox" key={`${isChecked}`} defaultChecked={isChecked}></input>
//             <span className="checkbox-checkmark"></span>
//         </label>
//     );
// }

const CheckboxControl = ({ isChecked = false, centerLabel = false, label = null, desc = null, jsText=false, hideCheckbox, ...props }) => {
    return (
        <label className="checkbox-container" onClick={props.onClick}>

            <div
                className={("label-item") + (centerLabel ? " --center" : "")}>
                <div className="label">
                    <span className={jsText ? "jsTextPrimary jsFontStyle" : "textPrimary fontStyle"}>{label}</span>
                </div>
                <div className="desc">
                    <p className={jsText ? "jsTextBody jsFontStyle" : "textBody fontStyle"}>{desc}</p>
                </div>
            </div>

            {hideCheckbox ? <></> : <><input {...props} type="checkbox" key={`${isChecked}`} defaultChecked={isChecked}></input>
            <span className="checkbox-checkmark"></span></>}
        </label>
    );
}

export default CheckboxControl;