import { createContext, useContext, useReducer, useEffect } from "react";
import undefGuard from "../utils/undefguard";
import UserStore from "../LocalStore/User.store";

const initialState = {
  initialized: false,
  settings: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    return {
      ...state,
      ...action.payload,
      initialized: true,
    };
  },
  DATA: (state, action) => {
    return {
      ...state,
      searchEngines: undefGuard(
        action.payload.searchEngines,
        state.searchEngines
      ),
      searchEngine: undefGuard(action.payload.searchEngine, state.searchEngine),
    };
  },
  SETTING: (state, action) => {
    UserStore.saveSettings({
      ...(state.settings || {}),
      ...(action.payload.settings || {}),
    });
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload.settings,
      },
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const DsContext = createContext({
  ...initialState,
  setSettings: (setting) => {},
});

function DatastoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log(">>> initializing datastore.");
  }, []);

  /** setting */

  const setSettings = (setting) => {
    dispatch({
      type: "SETTING",
      payload: {
        settings: setting,
      },
    });
  };

  return (
    <DsContext.Provider
      value={{
        ...state,
        setSettings,
      }}
    >
      {children}
    </DsContext.Provider>
  );
}

const useDs = () => useContext(DsContext);

export { DsContext, DatastoreProvider, useDs };
