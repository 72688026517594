import { useMemo } from "react";
import DialogManager from "../components/Dialog";
import HomeNavigationBar from "../components/HomeNavigationBar";
import Icons from "../components/Icons";
import { SidebarTabs } from "../components/Sidebar/constants";
import { useSk } from "../contexts/SkCtx";
import { DialogTabList, useUI } from "../contexts/UICtx";
import { useAuth } from "../contexts/AuthCtx";
import { ButtonText } from "../components/Buttons";

const SkeyLaptopArtwork = (props) => (
  <svg
    width={787}
    height={474}
    viewBox="0 0 787 474"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M80 9.11341C80 4.08022 84.0802 0 89.1134 0H700.724C705.757 0 709.837 4.08021 709.837 9.1134V421.242H80V9.11341Z"
      fill="#542E60"
    />
    <rect x={86} y={6} width={618} height={409} rx={3} fill="white" />
    <path
      d="M0 422.242H786.79V433.381C786.79 438.414 782.71 442.494 777.677 442.494H9.11342C4.08022 442.494 0 438.414 0 433.381V422.242Z"
      fill="#542E60"
    />
    <path
      d="M348 422H439.134C439.134 428.152 434.147 433.139 427.995 433.139H359.139C352.987 433.139 348 428.152 348 422Z"
      fill="white"
    />
    <mask
      id="mask0_792_334"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={687}
      y={13}
      width={10}
      height={10}
    >
      <rect
        x={687}
        y={22.0938}
        width={9.09382}
        height={9.09382}
        transform="rotate(-90 687 22.0938)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_792_334)">
      <path
        d="M692.305 19.8184C692.305 20.0268 692.231 20.2051 692.082 20.3534C691.934 20.5019 691.756 20.5762 691.547 20.5762C691.339 20.5762 691.16 20.5019 691.012 20.3534C690.863 20.2051 690.789 20.0268 690.789 19.8184C690.789 19.61 690.863 19.4316 691.012 19.2833C691.16 19.1348 691.339 19.0605 691.547 19.0605C691.756 19.0605 691.934 19.1348 692.082 19.2833C692.231 19.4316 692.305 19.61 692.305 19.8184ZM692.305 17.5449C692.305 17.7533 692.231 17.9316 692.082 18.0799C691.934 18.2284 691.756 18.3027 691.547 18.3027C691.339 18.3027 691.16 18.2284 691.012 18.0799C690.863 17.9316 690.789 17.7533 690.789 17.5449C690.789 17.3365 690.863 17.158 691.012 17.0095C691.16 16.8612 691.339 16.7871 691.547 16.7871C691.756 16.7871 691.934 16.8612 692.082 17.0095C692.231 17.158 692.305 17.3365 692.305 17.5449ZM692.305 15.2714C692.305 15.4798 692.231 15.6583 692.082 15.8068C691.934 15.9551 691.756 16.0293 691.547 16.0293C691.339 16.0293 691.16 15.9551 691.012 15.8068C690.863 15.6583 690.789 15.4798 690.789 15.2714C690.789 15.063 690.863 14.8846 691.012 14.736C691.16 14.5878 691.339 14.5136 691.547 14.5136C691.756 14.5136 691.934 14.5878 692.082 14.736C692.231 14.8846 692.305 15.063 692.305 15.2714Z"
        fill="#9CA3AF"
      />
    </g>
    <rect x={355} width={78} height={10} rx={5} fill="#542E60" />
    <g filter="url(#filter0_d_792_334)">
      <rect
        x={222}
        y={226.781}
        width={367}
        height={31.9594}
        rx={15.9797}
        fill="#F2F2F2"
      />
    </g>
    <rect
      x={583}
      y={177}
      width={149.191}
      height={297}
      rx={24.8651}
      fill="#542E60"
    />
    <rect
      x={586.157}
      y={180.158}
      width={142.876}
      height={290.488}
      rx={22.793}
      fill="white"
    />
    <rect
      x={630.659}
      y={188.051}
      width={53.1837}
      height={13.1233}
      rx={6.56163}
      fill="#542E60"
    />
    <defs>
      <filter
        id="filter0_d_792_334"
        x={216.141}
        y={223.053}
        width={382.98}
        height={47.9387}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={3.72859}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_792_334"
        />
        <feOffset dx={2.13062} dy={4.26125} />
        <feGaussianBlur stdDeviation={2.13062} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_792_334"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_792_334"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

function MainLayout({ children }) {
  const uiCtx = useUI();
  const skCtx = useSk();
  const authCtx = useAuth();

  const handleAddShortkey = (e) => {
    uiCtx.setDialog(true, DialogTabList.AddShort);
    skCtx.setCurrentShortkey({});
  };

  const isShowFloatingButton = useMemo(() => {
    switch (uiCtx.sidemenu_tab) {
      case SidebarTabs.Settings:
        return false;
      case SidebarTabs.Shorts:
        return true;
      default:
        return skCtx.setting["button"];
    }
  }, [skCtx.setting, uiCtx.sidemenu_tab]);

  return (
    <>
      <div className={("app-wrapper")+(skCtx.mobile_view ? " -mobile-view__IGNORED" : "")}>
        <HomeNavigationBar />
        {children}
        <DialogManager />
        {isShowFloatingButton && !skCtx.shortkeys_in_selection && !uiCtx.dialog_active && uiCtx.sidemenu_active && (
          <div onClick={handleAddShortkey} className="floating-collect-btn">
            <Icons.AddIcon />
          </div>
        )}
      </div>
      {/*skCtx.setting['minimalistic'] !== true && authCtx.initialized && authCtx.account && !authCtx.account.signed && !skCtx.mobile_view && <>

        <div className="skey-hero">
          <div className="skey-hero-row">
            <div className="row-inner">
              <div className="hero-brdr"></div>
              <div className="hero-feature">
                <div>
                  <h1>Execute with Shortkeys</h1>
                  <p>Shortkey is your smart launcher, it allow you to launch websites, search engines and AI all over your own keywords.</p>
                </div>
                <div>
                  <ButtonText>Create account</ButtonText>
                </div>
              </div>
            </div>
          </div>
          <div className="skey-laptop-artwork">
            <SkeyLaptopArtwork />
          </div>
        </div>
        <div className="skey-browser-ext">
          <div className="inner">
            <div>
              <h1>Browser Extension</h1>
              <p>Use Shortkey directly from your browser's URL input field</p>
            </div>
            <div>
              <span className="chrome-str">
                <img src="https://storage.googleapis.com/web-dev-uploads/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/HRs9MPufa1J1h5glNhut.png" />
              </span>
            </div>
          </div>
        </div>
        <div className="slider-section"></div>
        <div className="faq-section"></div>
        
        <div class="skey-footer">
          <div class="inner">
            <div class="footer-column">
              <h3>About</h3>
              <ul>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Mission</a></li>
                <li><a href="#">Jobs</a></li>
              </ul>
            </div>
            <div class="footer-column">
              <h3>Help</h3>
              <ul>
                <li><a href="#">Support</a></li>
                <li><a href="#">Tutorials</a></li>
                <li><a href="#">FAQ</a></li>
              </ul>
            </div>
            <div class="footer-column">
              <h3>Product</h3>
              <ul>
                <li><a href="#">Plans</a></li>
                <li><a href="#">Reviews</a></li>
                <li><a href="#">Extensions</a></li>
              </ul>
            </div>
            <div class="footer-column">
              <h3>Connect</h3>
              <ul>
                <li><a href="#">PressKit</a></li>
                <li><a href="#">Updates</a></li>
                <li><a href="#">Newsletter</a></li>
              </ul>
            </div>
            <div class="footer-column">
              <h3>Projects</h3>
              <ul>
                <li><a href="#">FAQNation</a></li>
                <li><a href="#">Bookmarker</a></li>
                <li><a href="#">UndoBird</a></li>
              </ul>
            </div>
            <div class="footer-column">
              <h3>Legal</h3>
              <ul>
                <li><a href="#">Cookies</a></li>
                <li><a href="#">Privacy policy</a></li>
                <li><a href="#">Terms of use</a></li>
              </ul>
            </div>
            <div class="footer-info">
              Shortkey is a keyword launcher that is operated and hosted entirely within the EU by Bookmarker.me
              Our target is to make the web accessible using own keywords and help to be more productive.
            </div>
            
            <div class="copyright">
              Copyright © 2024 Bookmarker.me
            </div>
          </div>
        </div>
        
        </>*/}
    </>
  );
}

export default MainLayout;
