import * as React from "react";
import Icons from ".";
const BookmarkFilledIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
      id="mask0_256_665"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
    </mask>
    <g mask="url(#mask0_256_665)">
      <path
        d="M5 21V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V21L12 18L5 21Z"
        fill={props.color || Icons.IconColors.idle}
      />
    </g>
    </svg>
);
export default BookmarkFilledIcon;
