import * as React from "react";
const AngleUpIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1225_115"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <rect width={24} height={24} fill="#532D63" />
    </mask>
    <g mask="url(#mask0_1225_115)">
      <path
        d="M7.4 15.3766L6 13.9766L12 7.97656L18 13.9766L16.6 15.3766L12 10.7766L7.4 15.3766Z"
        fill="#532D63"
      />
    </g>
  </svg>
);
export default AngleUpIcon;
