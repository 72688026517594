class UserStore {
  static Key = "settings";
  static initializedKey = "sk_initialized";
  static shortkeysOfflineModeKey = "sk_offline";

  static getSkInitialized() {
    return localStorage.getItem(UserStore.initializedKey) ? true : false;
  }

  static setSkInitialized() {
    return localStorage.setItem(UserStore.initializedKey, 'true');
  }

  static uninitializeSk() {
    return localStorage.removeItem(UserStore.initializedKey);
  }

  static getSkOfflineModeInitialized() {
    return localStorage.getItem(UserStore.shortkeysOfflineModeKey) ? true : false;
  }

  static setSkOfflineModeInitialized() {
    return localStorage.setItem(UserStore.shortkeysOfflineModeKey, 'true');
  }

  static uninitializeSkOfflineMode() {
    return localStorage.removeItem(UserStore.shortkeysOfflineModeKey);
  }

  static getSettings() {
    const settings = localStorage.getItem(UserStore.Key);
    return settings ? JSON.parse(settings) : {};
  }

  static saveSettings(newSettings) {
    const settings = UserStore.getSettings();
    const updatedSettings = { ...settings, ...newSettings };
    localStorage.setItem(UserStore.Key, JSON.stringify(updatedSettings));
  }

  static getTheme() {
    const settings = UserStore.getSettings();
    return settings.theme;
  }

  static setTheme(value) {
    UserStore.saveSettings({ theme: value });
  }

  static getInstantLauncher() {
    const settings = UserStore.getSettings();
    return settings.instant_launcher ?? true;
  }

  static setInstantLauncher(value) {
    UserStore.saveSettings({ instant_launcher: value });
  }

  static getCollectShort() {
    const settings = UserStore.getSettings();
    return settings.collect_short ?? true;
  }

  static setCollectShort(value) {
    UserStore.saveSettings({ collect_short: value });
  }

  static getSuggestions() {
    const settings = UserStore.getSettings();
    return settings.suggestions ?? true;
  }

  static setSuggestions(value) {
    UserStore.saveSettings({ suggestions: value });
  }

  static initInstantLauncher(enabled) {
    const settings = JSON.parse(localStorage.getItem(UserStore.Key)) || {};
    if (!settings.hasOwnProperty("instant_launcher")) {
      settings.instant_launcher = enabled;
      localStorage.setItem(UserStore.Key, JSON.stringify(settings));
    }
  }

  static initCollectShort(enabled) {
    const settings = JSON.parse(localStorage.getItem(UserStore.Key)) || {};
    if (!settings.hasOwnProperty("collect_short")) {
      settings.collect_short = enabled;
      localStorage.setItem(UserStore.Key, JSON.stringify(settings));
    }
  }

  static initSuggestions(enabled) {
    const settings = JSON.parse(localStorage.getItem(UserStore.Key)) || {};
    if (!settings.hasOwnProperty("suggestions")) {
      settings.suggestions = enabled;
      localStorage.setItem(UserStore.Key, JSON.stringify(settings));
    }
  }

  static removeSelf() {
    localStorage.removeItem(UserStore.Key);
  }
}

export default UserStore;
