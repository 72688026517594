import { createContext, useContext, useEffect, useReducer } from "react";
import { useCookies } from "react-cookie";
import ItemApi from "../LocalStore/Item.api";
import UserStore from "../LocalStore/User.store";
import ItemService from "../api/Item";
import AuthService from "../api/auth";
import InitService from "../api/init";
import { CookiesArray, CookiesList } from "../constants/cookies";
import { useSk } from "./SkCtx";

const initialState = {
  initialized: false,
  account: {
    signed: false,
  },
};

const handlers = {
  INITIALIZE: (state, action) => {
    return {
      ...state,
      ...action.payload,
      initialized: true,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  handleSetAuthCookie: (token) => {},
  updateSetting: (data) => {},
  createItem: (data, callback) => {},
  updateItem: (id, data, callback) => {},
  deleteItem: (data, rfrsh) => {},
  handleLogout: () => {},
  deleteAccount: () => {},
  resetApp: () => {},
  fetchAndAddCollection: async (item) => {},
  initialize: () => {}
});

function getExpirationTime() {
  var mo4 = new Date();
  var today = new Date();
  mo4.setDate(today.getDate() + 30 * 4);
  return mo4;
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies(CookiesArray);

  const skCtx = useSk();

  /**
   * Auth cookies
   */
  const handleSetAuthCookie = (token) => {
    setCookie(CookiesList.AccessToken, token, {
      expires: getExpirationTime(),
      path: '/',
      secure: true,
      sameSite: 'Strict'
    });
    setTimeout(() => {
      window.location.href = "/";
    }, [200]);
  };

  const handleLogout = () => {
    removeCookie(CookiesList.AccessToken);
    setTimeout(() => {
      window.location.href = "/";
    }, [30]);
  };

  /**
   * Init
   */
  const initSelf = (user) => {
    dispatch({
      type: "INITIALIZE",
      payload: {
        account: user,
      },
    });
  };

  const handleInit = (r) => {
    const { data } = r;
    if (data && data.user && data.user.signed === false) {
      handleInitLocal(data);
    } else {
      skCtx.setSetting(data.settings);
      skCtx.setItems(data.items);
      initSelf(data.user);
      console.log("D :: ", data)

      if(data.user.global_note) {
        localStorage.setItem("quicknote", data.user.global_note);
      }
    }
  };

  const handleInitLocal = async (initData = null) => {
    let setting = UserStore.getSettings();
    if (Object.keys(setting).length < 1) {
      setting = null;
    } else {
      setting = {
        instant: setting["se_instant"],
        suggest: setting["se_suggest"],
        add: setting["se_add"],
        random: setting["se_random"],
        below: setting["se_below"],
        newsletter: setting["se_newsletter"],
        button: setting["se_button"],
        minimalistic: setting['se_minimalistic'],
        improve_text: setting['se_improve_text'],
        ask_ai: setting['se_ask_ai'],
        auto_focus: setting['se_auto_focus'],
        new_tab: setting['se_new_tab'],
        categories: setting['se_categories']
      };
    }

    // let items = {
    //   count: 0,
    //   data: [],
    //   limit: -1,
    //   offset: 0
    // };
    let items = (await ItemApi.getItems())["data"];
    let init = initData;
    if (!setting) {
      if (!init) {
        init = await InitService.initialize({}, false);
      }
      setting = init["settings"];
    }
    if (!items) {
      items = (init["data"] || {})["items"];
    }
    skCtx.setSetting(setting);
    skCtx.setItems(items);
    initSelf({ signed: false });
  };

  const initialize = async () => {
    // if (state.account && state.account.signed) {
    //   InitService.initialize({}, false).then(handleInit);
    // } else {
    //   handleInitLocal();
    // }
    InitService.initialize({}, false).then(handleInit);
  };

  const toSetting = (k) => {
    return {
      se_instant: k["instant"],
      se_suggest: k["suggest"],
      se_add: k["add"],
      se_random: k["random"],
      se_below: k["below"],
      se_newsletter: k["newsletter"],
      se_button: k["button"],
      se_minimalistic: k["minimalistic"],
      se_ask_ai: k['ask_ai'],
      se_improve_text: k['improve_text'],
      se_auto_focus: k['auto_focus'],
      se_new_tab: k['new_tab'],
      se_categories: k['categories']
    };
  };

  const updateSetting = (setting_object) => {
    console.log("\n\nSETTING OBEJ :: ", setting_object)
    if (state.account.signed) {
      AuthService.update(
        toSetting({
          ...skCtx.setting,
          ...setting_object,
        }),
        {}
      ).then((r) => {
        console.log(">>> setting updated...");
        initialize();
      });
    } else {
      /** @TODO for local */
      UserStore.saveSettings(
        toSetting({
          ...skCtx.setting,
          ...setting_object,
        })
      );
      initialize();
    }
  };

  /**
   * Item methods -
   * 1. create
   * 2. update
   * 3. delete
   *
   * Final -
   * 1. create/update
   * 2. delete
   */

  const createItem = (
    data = {
      title: null,
      target: null,
      shortkeys: null,
    },
    callback
  ) => {
    if (state.account && state.account.signed) {
      ItemService.putItem(null, data, {}).then((response) => {
        if (response && response["data"] && response["data"]["updates"]) {
          skCtx.setItems(response["data"]["updates"]);
        }
        if (
          response &&
          response["data"] &&
          response["data"]["operation_result"]
        ) {
          callback(response["data"]["operation_result"]);
        } else {
          callback(null);
        }
      });
    } else {
      /** @TODO for local */
      console.log("Creating in local.");
      ItemApi.createOrUpdate(null, data, skCtx.setting["below"] || false).then(
        (response) => {
          if (response && response["data"] && response["data"]["updates"]) {
            skCtx.setItems(response["data"]["updates"]);
          }
          console.log("R :: ", response);
          if (
            response &&
            response["data"] &&
            response["data"]["operation_result"]
          ) {
            callback(response["data"]["operation_result"]);
          } else {
            callback(null);
          }
        }
      );
    }
  };

  const updateItem = (
    id,
    data = {
      title: null,
      target: null,
      shortkeys: null,
      order: null,
    },
    callback = (l) => {}
  ) => {
    if (state.account && state.account.signed) {
      ItemService.putItem(id, data, {}).then((response) => {
        if (response && response["data"] && response["data"]["updates"]) {
          skCtx.setItems(response["data"]["updates"]);
        }
      });
    } else {
      /** @TODO for local */
      console.log("Updating in local.");
      ItemApi.createOrUpdate(id, data, skCtx.setting["below"] || false).then(
        (response) => {
          if (response && response["data"] && response["data"]["updates"]) {
            skCtx.setItems(response["data"]["updates"]);
          }
          console.log("R :: ", response);
          if (
            response &&
            response["data"] &&
            response["data"]["operation_result"]
          ) {
            callback(response["data"]["operation_result"]);
          } else {
            callback(null);
          }
        }
      );
    }
  };

  const deleteItem = (id, rfrs = true) => {

    console.log("Deleting :: ", id, rfrs, skCtx.default_search)

    if(skCtx.default_search) {
      if(`${skCtx.default_search._id}` === `${id}`) {
        // localStorage.setItem("default_search", JSON.stringify({
        //   "_id": "-10",
        //   "title": "Google",
        //   "shortkeys": ["google", "g", "search"],
        //   "target": "https://www.google.com/search?q=@@@",
        //   "pinned": false,
        //   "order": 10
        // }));
        // skCtx.setDefaultSearch({
        //   "_id": "-10",
        //   "title": "Google",
        //   "shortkeys": ["google", "g", "search"],
        //   "target": "https://www.google.com/search?q=@@@",
        //   "pinned": false,
        //   "order": 10
        // });
        localStorage.setItem("default_search", JSON.stringify({
          "_id": "-10",
          "title": "DuckDuckGo",
          "shortkeys": ["duckduckgo"],
          "target": "https://duckduckgo.com/?q=@@@",
          "pinned": false,
          "order": 10
        }));
        skCtx.setDefaultSearch({
          "_id": "-10",
          "title": "DuckDuckGo",
          "shortkeys": ["duckduckgo"],
          "target": "https://duckduckgo.com/?q=@@@",
          "pinned": false,
          "order": 10
        });
      }
    }

    if (state.account && state.account.signed) {
      ItemService.deleteItem(id, {}).then((response) => {
        if (response && response["data"] && response["data"]["updates"] && rfrs) {
          skCtx.setItems(response["data"]["updates"]);
        }
      });
    } else {
      /** @TODO for local */
      ItemApi.deleteOne(id).then((response) => {
        if (response && response["data"] && response["data"]["updates"] && rfrs) {
          skCtx.setItems(response["data"]["updates"]);
        }
      });
    }
  };

  const clearShortkeys = async () => {
    
  }

  const resetApp = () => {
    let c = window.confirm("Are you sure? It will remove cookies, your data and revert to the default settings.");
    if(c) {
      for (let i = 0; i < CookiesArray.length; i++) {
        removeCookie(CookiesArray[i]);
      }
      localStorage.clear();
      setTimeout(() => {
        window.location.href = `/`;
      }, 2000);
    }
  }

  const deleteAccount = () => {
    AuthService.removeAc({}).then((r) => {
      setTimeout(() => {
        // window.location.href = "/";
        handleLogout();
      }, [30]);
    });
  };

  /**
   * Effects
   */
  useEffect(() => {
    console.log(">>> authctx mounting.");
    initialize();
  }, []);
  const createItemAsync = (itemz) => {
    return new Promise((resolve, reject) => {
      createItem({
        title: itemz.title,
        shortkeys: itemz.keywords,
        target: itemz.url || itemz.prompt
      }, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  };
  
  const fetchAndAddCollection = async (item) => {
    let a = await InitService.fetchPlusCollectionValues();
    let i = a[item.target];
    if (i) {
      console.log("Add -> ", i, i.length);
      let c = window.confirm(`Are you sure do you want to add ${i.length} items in your list?`);
      if (c) {
        console.log("I :: ", i, a, item);
        if (i.length > 0) {
          skCtx.setCollectionLoading(true);
        }
        else
        {
          return;
        }
        for (let k = 0; k < i.length; k++) {
          const itemz = i[k];
          try {
            await createItemAsync(itemz);
            // if (k === i.length - 2) {
              // skCtx.setCollectionLoading(false);
            // }
          } catch (error) {
            console.error("Error adding item:", error);
            // Handle the error, e.g., stop the process or continue with the next item
          }
        }
        skCtx.setCollectionLoading(false);
      }
    }
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        createItem,
        updateItem,
        deleteItem,
        handleSetAuthCookie,
        updateSetting,
        handleLogout,
        deleteAccount,
        resetApp,
        fetchAndAddCollection,
        initialize
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
