import { forwardRef } from "react";

export const TextArea = forwardRef(({
    placeholder,
    ...props
}, ref) => {
    return (
        <div
            className="inputElement textArea">
                <div className="auto"></div>
                <div>
                    <textarea ref={ref} placeholder={placeholder} {...props} />
                </div>
                <div className="auto"></div>
        </div>
    );
});

export default TextArea;