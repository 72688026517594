import * as React from "react";
const CheckIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_240_554"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <rect width={24} height={24} fill="#532D63" />
    </mask>
    <g mask="url(#mask0_240_554)">
      <path
        d="M9.54961 18.0016L3.84961 12.3016L5.27461 10.8766L9.54961 15.1516L18.7246 5.97656L20.1496 7.40156L9.54961 18.0016Z"
        fill="#532D63"
      />
    </g>
  </svg>
);
export default CheckIcon;
