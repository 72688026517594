/**
 * 
 * type = "horiz" | "vert"
 */

function Divider({
    type = "horiz",
    className = ""
}) {
    return (
        <span className={([type, className].join(" "))}>
            <span className="divider"></span>
        </span>
    );
}

export default Divider;
