import { apiRoutes } from ".";
import { callApi } from "./caller";

export default class AuthService {
  static async loginGoogle(
    data = { token: null },
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "put",
      path: apiRoutes.auth.google_login,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: data,
    });
  }

  static async login(
    data = { authcode: null },
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "post",
      path: apiRoutes.auth.login,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: data
    });
  }

  static async loginLoginSign(
    data = { authcode: null, connectedCode: null },
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "post",
      path: apiRoutes.auth.loginsign,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: data
    });
  }

  static async update(
    data = {},
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "patch",
      path: apiRoutes.user.update,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: data
    });
  }

  static async updateNote(
    data = {},
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "put",
      path: apiRoutes.auth.update_note,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: data
    });
  }

  static async me(
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "get",
      path: apiRoutes.auth.me,
      cancelToken: req.cancelToken,
      signal: req.signal,
    });
  }

  static async clearShortkeys(
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "post",
      path: apiRoutes.auth.clear_shortkeys,
      cancelToken: req.cancelToken,
      signal: req.signal,
      payload: {}
    });
  }

  static async removeAc(
    req = {
      cancelToken: undefined,
      signal: undefined,
    }
  ) {
    return await callApi({
      method: "delete",
      path: apiRoutes.user.remove,
      cancelToken: req.cancelToken,
      signal: req.signal,
    });
  }
}
