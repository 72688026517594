import { createContext, useContext, useEffect, useReducer } from "react";
import UserStore from "../LocalStore/User.store";
import { SidebarSubTabsList } from "../components/Sidebar/constants";
import undefGuard from "../utils/undefguard";

export const DialogTabList = {
  Start: "dStart",
  Cookies: "dCookies",
  Terms: "dTerms",
  Privacy: "dPrivacy",
  ViewShort: "dViewShort",
  AddShort: "dAddShort",
  AddSearchEngine: "dAddSearchEngine",
  EditShort: "dEditShort",
  Loading: "dLoading",
  ExtensionSetup: "dExtSetup"
};

const initialState = {
  initialized: false,
  sidemenu_active: false,
  sidemenu_tab: null,
  sidemenu_last_tab: null,
  sidemenu_sub_tab: SidebarSubTabsList.All,
  data: {},
  ui_debug_enabled: false,
  dialog_active: false,
  dialog_tab: null,
  dialog_args: [],
};

const handlers = {
  SET_DIALOG: (state, action) => {
    return {
      ...state,
      dialog_active: undefGuard(
        action.payload["dialog_active"],
        state.dialog_active
      ),
      dialog_tab: undefGuard(action.payload["dialog_tab"], state.dialog_tab),
      data: undefGuard(action.payload["data"], state.data),
      dialog_args: undefGuard(action.payload["dialog_args"], state.dialog_args),
    };
  },
  SHOW_SIDEBAR_FALLBACK: (state, action) => {
    return {
      ...state,
      sidemenu_fallback_active: true,
    };
  },
  SHOW_SIDEBAR: (state, action) => {
    return {
      ...state,
      sidemenu_active: true,
    };
  },
  HIDE_SIDEBAR_FALLBACK: (state, action) => {
    return {
      ...state,
      sidemenu_fallback_active: false,
    };
  },
  HIDE_SIDEBAR: (state, action) => {
    return {
      ...state,
      sidemenu_active: false,
    };
  },
  CHANGE_TAB: (state, action) => {
    console.log("🚀 ~ action:", action);
    return {
      ...state,
      sidemenu_tab: action.payload.tab,
      sidemenu_last_tab: state.sidemenu_tab,
    };
  },
  CHANGE_SUB_TAB: (state, action) => {
    return {
      ...state,
      sidemenu_sub_tab: action.payload.tab,
    };
  },
  DATA: (state, action) => {
    return {
      ...state,
      data: {
        ...(state.data || {}),
        ...action.payload.data,
      },
    };
  },
  ONLINE: (state, action) => {
    return {
      ...state,
      isOnline: action.payload.isOnline,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const UIContext = createContext({
  ...initialState,
  setDialog: (visibility = undefined, tab = undefined) => { },
  setSidemenuVisibility: (status) => { },
  setSidemenuTab: (tab) => { },
  setSidemenuSubTab: (tab) => { },
  setData: (data) => { },
  c: (clsNames) => { },
});

function UIProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleHashChange = (e) => {
    const updatedHash = window.location.hash;
    if (updatedHash === "#customizeCookies") {
      setDialog(true, DialogTabList.Cookies);
    } else if (updatedHash === "#setup") {
      setDialog(true, DialogTabList.Start);
    } else if (updatedHash === "#privacy") {
      setDialog(true, DialogTabList.Privacy);
    } else if (updatedHash === "#terms") {
      setDialog(true, DialogTabList.Terms);
    }
    // else if (updatedHash === "#collect") {
    //     setDialog(true, DialogTabList.AddShort);
    // }
    else if (updatedHash === "#shorts") {
      setSidemenuTab("shorts");
      if (!state.sidemenu_active) {
        setSidemenuVisibility(true);
      }
    } else if (updatedHash === "#setting") {
      setSidemenuTab("settings");
      if (!state.sidemenu_active) {
        setSidemenuVisibility(true);
      }
    } else {
      if (state.dialog_active) {
        setDialog(false);
      }

      if (state.sidemenu_active) {
        setSidemenuVisibility(false);
      }
    }
  };

  useEffect(() => {
    let theme = UserStore.getTheme();

    if (theme) {
      if (!document.documentElement.classList.contains(theme)) {
        document.documentElement.className = theme;
      }
    }

    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const removeHashWhenDialogClosed = (which, visibility) => {
    if (!visibility) {
      if (which === "dialog") {
        if (
          window.location.hash === "#customizeCookies" ||
          window.location.hash === "#setup" ||
          window.location.hash === "#privacy" ||
          window.location.hash === "#terms"
        ) {
          const urlWithoutFragment = window.location.href.split("#")[0];
          window.history.replaceState(null, "", urlWithoutFragment);
        }
      } else if (which === "sidebar") {
        if (
          window.location.hash === "#shorts" ||
          window.location.hash === "#setting"
        ) {
          const urlWithoutFragment = window.location.href.split("#")[0];
          window.history.replaceState(null, "", urlWithoutFragment);
        }
      }
    }
  };

  /** Dialog */

  /** params[0] = visibility, params[1] = tab */
  const setDialog = (
    visibility = undefined,
    tab = undefined,
    data = undefined,
    dialog_args = undefined
  ) => {
    if (!visibility) {
      if (state.dialog_tab === DialogTabList.AddSearchEngine) {
        setData({
          searchengine: {},
        });
      } else if (state.dialog_tab === DialogTabList.AddShort) {
        setData({
          item_short: {},
          item_key: {},
        });
      }
    }

    dispatch({
      type: "SET_DIALOG",
      payload: {
        dialog_active: visibility,
        dialog_tab: tab,
        data: data,
        dialog_args: dialog_args,
      },
    });
    removeHashWhenDialogClosed("dialog", visibility);
  };

  const setSidemenuTab = async (tab) => {
    dispatch({
      type: "CHANGE_TAB",
      payload: {
        tab: tab,
      },
    });
  };

  const setSidemenuSubTab = async (tab) => {
    dispatch({
      type: "CHANGE_SUB_TAB",
      payload: {
        tab: tab,
      },
    });
  };

  const setSidemenuVisibility = async (status) => {
    if (status) {
      dispatch({
        type: "SHOW_SIDEBAR_FALLBACK",
      });
      await setTimeout(() => {
        dispatch({
          type: "SHOW_SIDEBAR",
        });
      }, 20);
    } else {
      // dispatch({
      //   type: "HIDE_SIDEBAR",
      // });
      await setTimeout(() => {
        dispatch({
          type: "HIDE_SIDEBAR_FALLBACK",
        });
        dispatch({
          type: "HIDE_SIDEBAR",
        });
        dispatch({
          type: "CHANGE_TAB",
          payload: {
            tab: null,
          },
        });
      }, 100);
    }
    removeHashWhenDialogClosed("sidebar", status);
  };

  const setData = (data) => {
    dispatch({
      type: "DATA",
      payload: {
        data: {
          ...data,
        },
      },
    });
  };

  const c = (clsNames) => {
    let c = (clsNames || "").trim();
    return state.ui_debug_enabled
      ? c.length > 0
        ? c + " lcheck"
        : c + "lcheck"
      : c;
  };

  return (
    <UIContext.Provider
      value={{
        ...state,
        setDialog,
        setSidemenuVisibility,
        setSidemenuTab,
        setData,
        setSidemenuSubTab,
        c,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

const useUI = () => useContext(UIContext);

export { UIContext, UIProvider, useUI };
