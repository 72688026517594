const ToggleSwitchControl = ({ isChecked = false, centerLabel = false, label = null, desc = null, jsText = false, hideSwitch, ...props }) => {
    return (
        <label className="toggle-container" onClick={props.onClick}>
            <div className={("label-item") + (centerLabel ? " --center" : "")}>
                <div className="label">
                    <span className={jsText ? "jsTextPrimary jsFontStyle" : "textPrimary fontStyle"}>{label}</span>
                </div>
                <div className="desc">
                    <p className={jsText ? "jsTextBody jsFontStyle" : "textBody fontStyle"}>{desc}</p>
                </div>
            </div>

            {!hideSwitch ? <>
                <input {...props} type="checkbox" key={`${isChecked}`} defaultChecked={isChecked}></input>
                <span className="toggle-switch"></span>
            </> : null}
        </label>
    );
}

export default ToggleSwitchControl;