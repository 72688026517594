import * as React from "react";
import Icons from ".";
const PencilIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_217_692"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_217_692)">
            <path
                d="M19.3 8.92891L15.05 4.72891L16.45 3.32891C16.8333 2.94557 17.3043 2.75391 17.863 2.75391C18.421 2.75391 18.8917 2.94557 19.275 3.32891L20.675 4.72891C21.0583 5.11224 21.2583 5.57491 21.275 6.11691C21.2917 6.65824 21.1083 7.12057 20.725 7.50391L19.3 8.92891ZM17.85 10.4039L7.25 21.0039H3V16.7539L13.6 6.15391L17.85 10.4039Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default PencilIcon;
