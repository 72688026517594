import { Outlet, useLocation, useRoutes } from "react-router";
import MainLayout from "../layouts/main";
import MainPage from "../pages/main";
import AuthPage from "../pages/auth";
import { LaunchPage } from "../pages/launch";
import LoginPage from "../pages/login";

export function Router() {

    return useRoutes([
        {
            path: '/',
            element: <MainLayout><Outlet /></MainLayout>,
            children: [
                {
                    path: '/',
                    element: <MainPage />
                },
                {
                    path: 'auth',
                    element: <AuthPage />
                },
            ]
        },
        {
            path: 'login',
            element: <LoginPage />
        },
        {
            path: '*',
            element: <LaunchPage />
        }
    ]);

}