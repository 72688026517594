function ButtonIcon({
    children,
    ...props
}) {
    return (
        <button {...props} className={["buttonIcon", props.className].join(" ")}>
            <span>
                {children}
            </span>
        </button>
    );
}

function ButtonText({
    children,
    textClassName,
    style,
    className,
    ...props
}) {
    return (
        <button {...props} className={[className || "", "buttonText"].join(" ")} style={style}>
            <span className={[textClassName].join(" ")}>
                {children}
            </span>
        </button>
    );
}

function Button({
    icon,
    text,
    textClassName,
    ...props
}) {
    return (
        <button
            {...props} className={["button", props.className].join(" ")}>
                {icon && <span>
                    {icon}
                </span>}
                {text && <span className={textClassName}>
                    {text}
                </span>}
        </button>
    );
}

export {
    ButtonIcon,
    ButtonText,
    Button
}