import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { getApiLoginUrl } from "../../env";
import { ButtonIcon, ButtonText } from "../Buttons";
import Icons from "../Icons";

export default function DialogStartTab() {

    const authCtx = useAuth();
    const uiCtx = useUI();

    const onLoginClick = (e) => {
        authCtx.markCookiesDeployed();
        window.location.assign(getApiLoginUrl());
    }

    const onCookiesClick = (e) => {
        // e.preventDefault();
    }

    const onContinueWithoutAccountClick = (e) => {
        authCtx.markCookiesDeployed();
        uiCtx.setDialog(false);
    }

    const onCloseClick = (e) => {
        authCtx.markCookiesDeployed();
        uiCtx.setDialog(false);
    }

    return (
        <>
            <div
                className="dialog_col titlebar common-w">
                    <div>

                    </div>
                    <div>
                        <ButtonIcon onClick={onCloseClick}>
                            <Icons.CloseIcon color={Icons.IconColors.active} />
                        </ButtonIcon>
                    </div>
            </div>
            <div
                className="dialog_col center-layout mb-12">
                    <img style={{maxWidth: 120}} src="/assets/circle_logo.png" />
            </div>
            <div
                className="dialog_col center-layout mb-12">
                    <p className="jsTextBody start-dialog-desc-text">By continuing, we assume your permission to deploy our <a className="jsTextBody underline" onClick={onCookiesClick} href="#customizeCookies">cookies</a></p>
            </div>
            <div
                className="dialog_col center-layout pd-12">
                    <ButtonText onClick={onLoginClick} style={{width: '100%', maxWidth: '400px'}} textClassName={"jsText"}>Login</ButtonText>
            </div>
            <div
                className="dialog_col center-layout mb-12 pd-12">
                    <ButtonText onClick={onContinueWithoutAccountClick} className={"secondaryButton"} style={{width: '100%', maxWidth: '400px'}} textClassName={"jsText"}>Continue without account</ButtonText>
            </div>
            <div
                className="dialog_col center-layout pd-12">
            </div>
        </>
    );
}