import * as React from "react";
import Icons from ".";
const SettingIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_258_668"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_258_668)">
            <path
                d="M12.8889 9.33333V7.55556H15.5556V4H17.3333V7.55556H20V9.33333H12.8889ZM15.5556 20V11.1111H17.3333V20H15.5556ZM6.66667 20V16.4444H4V14.6667H11.1111V16.4444H8.44444V20H6.66667ZM6.66667 12.8889V4H8.44444V12.8889H6.66667Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default SettingIcon;
