import * as React from "react";
import Icons from ".";
const MenuIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_207_689"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_207_689)">
            <path
                d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default MenuIcon;
