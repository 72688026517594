import * as React from "react";
import Icons from ".";
const LabelIcon = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_267_648"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill={props.color || Icons.IconColors.idle} />
        </mask>
        <g mask="url(#mask0_267_648)">
            <path
                d="M21 12L16.65 18.15C16.4667 18.4167 16.2292 18.625 15.9375 18.775C15.6458 18.925 15.3333 19 15 19H5C4.45 19 3.97917 18.8042 3.5875 18.4125C3.19583 18.0208 3 17.55 3 17V7C3 6.45 3.19583 5.97917 3.5875 5.5875C3.97917 5.19583 4.45 5 5 5H15C15.3333 5 15.6458 5.075 15.9375 5.225C16.2292 5.375 16.4667 5.58333 16.65 5.85L21 12ZM18.55 12L15 7H5V17H15L18.55 12Z"
                fill={props.color || Icons.IconColors.idle}
            />
        </g>
    </svg>
);
export default LabelIcon;
