import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { ButtonIcon, ButtonText } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";

export default function DialogPrivacyTab() {

    const authCtx = useAuth();
    const uiCtx = useUI();

    const onCloseClick = (e) => {
        uiCtx.setDialog(false);
    }

    return (
        <>
            <div
                className="dialog_col titlebar common-w">
                    <div>
                        <ButtonIcon onClick={() => {window.history.back()}}>
                            <Icons.BackIcon color={Icons.IconColors.active} />
                        </ButtonIcon>
                    </div>
                    <div>
                        <ButtonIcon onClick={onCloseClick}>
                            <Icons.CloseIcon color={Icons.IconColors.active} />
                        </ButtonIcon>
                    </div>
            </div>
            <div
                className="dialog_col center-layout pd-12">
                    <span className="jsTextPrimary start-dialog-heading-text">Privacy</span>
            </div>
            <div
                className="dialog_col center-layout mb-12">
                    <p className="jsTextBody start-dialog-desc-text">
                    At Shortkey.org, accessible from www.shortkey.org, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Shortkey.org and how we use it.  If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.  This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Shortkey.org. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator. By using our website, you hereby consent to our Privacy Policy and agree to its terms. The personal information that you are asked to provide, and the reasons why you are asked to provide it, will
                    </p>
            </div>
            <div
                className="dialog_col center-layout pd-12">
            </div>
        </>
    );
}