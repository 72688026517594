function LoadingPage({}) {

    let className = "--purple";

    return (
        <div
            className="loadingWrapper">

                <div className="loading">
                    <div className={("sk-ldr") + (className ? " " + className : "")}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>

        </div>
    );

}

export default LoadingPage;