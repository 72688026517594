export const apiRoutes = {
    status: '',
    init: {
        initialize: 'init'
    },
    auth: {
        loginsign: 'auth/login_loginsign',
        google_login: 'auth/google',
        me: 'auth/me',
        login: 'auth/login',
        update_note: 'auth/update_note',
        clear_shortkeys: 'auth/reset'
    },
    item: {
        initialize: 'item'
    },
    short: {
        initialize: 'short'
    },
    key: {
        initialize: 'key'
    },
    user: {
        update: "user",
        remove: "user"
    }
}

export function getBearerTokenFormat(tk) {
    return `Bearer ${tk}`;
}