import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthCtx";
import { useUI } from "../../contexts/UICtx";
import { Button, ButtonIcon, ButtonText } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";
import Input from "../Input";
import { useSk } from "../../contexts/SkCtx";


export default function AddSearchEngineTab() {

    const authCtx = useAuth();
    const uiCtx = useUI();
    const skCtx = useSk();

    const onCloseClick = (e) => {
        uiCtx.setDialog(false);
    }

    const onRemove = (e) => {
        skCtx.deleteSearchEngine(uiCtx.data['searchengine']._id);
        uiCtx.setDialog(false);
    }

    const onDone = (e) => {
        uiCtx.setDialog(false);
    }

    const save = (data) => {
        let se = {...uiCtx.data['searchengine'], ...(data || {})};
        if(se._id) {
            /** update */
            skCtx.updateSearchEngine(se._id, se);
        }
        else
        {
            /** create new and save _id in search_engine */
            let id = skCtx.addSearchEngine(se);
            uiCtx.setData({
                searchengine: {
                    ...(uiCtx.data['searchengine'] || {}),
                    _id: id
                }
            })
        }
    }

    const onTitleKeyUp = (e) => {
        const val = {title: e.target.value};
        uiCtx.setData({
            searchengine: {
                ...(uiCtx.data['searchengine'] || {}),
                ...val
            }
        });
        save(val);
    }

    const onURLKeyUp = (e) => {
        const val = {url: e.target.value};
        uiCtx.setData({
            searchengine: {
                ...(uiCtx.data['searchengine'] || {}),
                ...val
            }
        });
        save(val);
    }

    const onShortcutKeyUp = (e) => {
        let key = e.key;
    }

    return (
        <>
            <div className="spacer mb-12"></div>
            <div
                className="dialog_col titlebar common-shorts-w">
                <div>
                </div>
                <div>
                    <ButtonIcon onClick={onCloseClick}>
                        <Icons.CloseIcon color={Icons.IconColors.active} />
                    </ButtonIcon>
                </div>
            </div>
            <div className="spacer h-30"></div>
            <div
                className="dialog_col input-container common-w">
                    <div className="inner-col">
                        <Input autoFocus={true} onChange={onTitleKeyUp} defaultValue={uiCtx.data['searchengine'] ? uiCtx.data['searchengine'].title : ""} placeholder={"Title"} />
                    </div>
            </div>
            <div className="spacer h-30"></div>
            <>
                <div
                    className="dialog_col input-container common-w">
                        <div className="inner-col">
                            <Input onChange={onURLKeyUp} defaultValue={uiCtx.data['searchengine'] ? uiCtx.data['searchengine'].url : ""} placeholder={"URL https://example.com/@@@"} />
                        </div>
                </div>
                <div className="spacer mb-6"></div>
                <div className="dialog_col common-w input-container">
                    <div className="inner-col">
                        <span className="textBody">Insert @@@ in the URL where you want the search terms to appear.</span>
                    </div>
                </div>
            </>
            <div className="spacer h-30"></div>
            <div
                className="dialog_col input-container common-w">
                    <div className="inner-col">
                        <Input onKeyUp={onShortcutKeyUp} placeholder={"Shortcut key (press the key)"} />
                    </div>
            </div>
            <div className="spacer h-30"></div>
            <div
                className="dialog_col input-container buttons-container common-w">
                    <div className="inner-col">
                        <Button disabled={!(uiCtx.data['searchengine'] && uiCtx.data['searchengine']._id || false)} onClick={onRemove} className={"centerContent"} text={"Remove"} icon={<Icons.DeleteIcon />} />
                        <Button onClick={onDone} className={"centerContent"} text={"Done"} icon={<Icons.EnterIcon />} />
                    </div>
            </div>
            <div className="spacer h-30"></div>
            <div className="spacer mb-12"></div>
        </>
    );
}