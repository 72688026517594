import React from "react";

export default function Select({ children, ...props }) {
  const className = `h-[60px] border-none rounded-full 
    block w-full text-[16px] text-[#5D366D] font-normal`;
  const mergeClassName = props.className
    ? `${props.className} ${className}`
    : className;
  return (
    <select
      {...props}
      className={mergeClassName}
      style={{
        backgroundPositionX: "100%",
        backgroundPositionY: "5px",
        background:
          "url(https://api.iconify.design/akar-icons/chevron-down.svg) right 15px center / 20px no-repeat",
        appearance: "none",
        backgroundColor: "var(--color-bg-alt2)",
        backgroundSize: "17px",
        height: props.height,
        padding: '0 12px',
        paddingLeft: 20
      }}
    >
      {children}
    </select>
  );
}
