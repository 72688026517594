import * as React from "react";
import Icons from ".";
const ControlIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_207_686"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <rect width={24} height={24} fill="#616161" />
    </mask>
    <g mask="url(#mask0_207_686)">
      <path
        d="M13 9V7H16V3H18V7H21V9H13ZM16 21V11H18V21H16ZM6 21V17H3V15H11V17H8V21H6ZM6 13V3H8V13H6Z"
        fill={props.color || Icons.IconColors.idle}
      />
    </g>
  </svg>
);
export default ControlIcon;
